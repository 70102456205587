import React from 'react'
import classNames from 'classnames'

import css from './SimpleCard.styles.scss'

import { SimpleCardProps } from './SimpleCard.types'

const SimpleCard: React.FC<SimpleCardProps> = ({
  className,
  leftIcon,
  rightIcon,
  title,
  content,
  onClick,
  role = 'display',
}) => {
  const SimpleCardClasses = classNames(css.SimpleCard, className)

  const handleClick = (...args: any) => {
    if (onClick) {
      onClick(args)
    }
  }

  const renderTitle = () => {
    switch (role) {
      case 'link':
        return (
          <a className={css.SimpleCard__title} onClick={handleClick}>
            {title}
          </a>
        )
      case 'button':
        return (
          <button className={css.SimpleCard__title} onClick={handleClick}>
            {title}
          </button>
        )
      case 'display':
      default:
        return <div className={css.SimpleCard__title}>{title}</div>
    }
  }

  return (
    <div className={SimpleCardClasses}>
      <div className={css.SimpleCard__leftIcon}>{leftIcon}</div>
      <div className={css.SimpleCard__body}>
        {renderTitle()}
        {content && <div className={css.SimpleCard__content}>{content}</div>}
      </div>
      <div className={css.SimpleCard__rightIcon}>{rightIcon}</div>
    </div>
  )
}

export default SimpleCard
