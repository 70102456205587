import React from 'react'
import classNames from 'classnames'

import css from './GiftTrackerProgress.styles.scss'

import { GiftTrackerProgressProps } from './GiftTrackerProgress.types'
import CircularProgressBar from '../CircularProgressBar/CircularProgressBar'
import Gift from '../../icons/Gift.svg'

const GiftTrackerProgress: React.FC<GiftTrackerProgressProps> = ({
  className,
  value,
}) => {
  const GiftTrackerProgressClasses = classNames(
    css.GiftTrackerProgress,
    className
  )

  return (
    <div className={GiftTrackerProgressClasses}>
      <div className={css.GiftTrackerProgress__iconContainer}>
        <Gift filled={true} className={css.GiftTrackerProgress__icon} />
      </div>
      <div className={css.GiftTrackerProgress__container}>
        <CircularProgressBar value={value} />
      </div>
    </div>
  )
}

export default GiftTrackerProgress
