import PropTypes from 'prop-types'
import { map } from 'lodash'
import { Link } from 'react-router'
import classNames from 'classnames'
import { helloBabyPostPath } from 'lib/urls'
import { StyledSubHeading } from 'components/headings'
import { PostShape, TopicShape } from 'hello_baby/lib/prop-types'
import css from './post-list.scss'

const headerIcons = {
  strollers:
    '//images.babylist.com/image/upload/v1529687758/gear-icons/stroller.svg',
  'car-seat':
    '//images.babylist.com/image/upload/v1529687797/gear-icons/car.svg',
  carriers:
    '//images.babylist.com/image/upload/v1529687920/gear-icons/carrier.svg',
  'feeding-gear':
    '//images.babylist.com/image/upload/v1529687903/gear-icons/bottle.svg',
  'sleep-gear':
    '//images.babylist.com/image/upload/v1529687166/gear-icons/moon.svg',
  diapering:
    '//images.babylist.com/image/upload/v1529687901/gear-icons/diaper.svg',
  'baby-clothes':
    '//images.babylist.com/image/upload/v1529687899/gear-icons/clothes.svg',
  playroom:
    '//images.babylist.com/image/upload/v1529687897/gear-icons/rattle.svg',
  seasonal: '//images.babylist.com/image/upload/v1529687895/gear-icons/sun.svg',
  toys: '//images.babylist.com/image/upload/v1529687166/gear-icons/train.svg',
  nursery:
    '//images.babylist.com/image/upload/v1529687166/gear-icons/crib_new.svg',
  bathtime:
    '//images.babylist.com/image/upload/v1529687166/gear-icons/duck.svg',
  sponsored:
    '//images.babylist.com/image/upload/v1607649367/gear-icons/sponsored.png',
  childproofing:
    '//images.babylist.com/image/upload/v1607649367/gear-icons/childproof-outlet.png',
  holiday:
    '//images.babylist.com/image/upload/v1607649367/gear-icons/holiday-bow.png',
}

const PostList = ({ posts, topic }) => {
  let viewMore = false
  const postsToDisplay = posts.slice(0, 5)

  if (posts.length > 5) {
    viewMore = true
  }

  return (
    <>
      <StyledSubHeading className="h4 text-bold mtn mbm">
        <span
          className={classNames(css.headerIcon, 'display-inline')}
          style={{ backgroundImage: `url(${headerIcons[topic.slug]})` }}
        />
        {topic.name}
      </StyledSubHeading>
      <ul className="list-unstyled">
        {map(postsToDisplay, (post) => {
          return (
            <li key={post.id} className="small mvm">
              <Link to={helloBabyPostPath(post.slug)}>{post.title}</Link>
            </li>
          )
        })}
      </ul>
      {viewMore ? (
        <Link
          key="view-more"
          to={helloBabyPostPath(topic.slug)}
          className="h7 text-uppercase text-bold"
        >
          View All
        </Link>
      ) : null}
    </>
  )
}

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape(PostShape)).isRequired,
  topic: PropTypes.shape(TopicShape).isRequired,
}

export default PostList
