import React from 'react'

interface WalmartStoreProps {
  className?: string
  rounded?: boolean
}

const WalmartStore: React.FC<WalmartStoreProps> = ({
  className,
  rounded = true,
}: WalmartStoreProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="#0071CE"
      />
    ) : (
      <rect width="24" height="24" fill="#0071CE" />
    )}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2046 9.05089C12.6643 9.05089 13.037 8.80986 13.0929 8.50704L13.5401 3.41465C13.5401 2.91407 12.95 2.5 12.2046 2.5C11.4593 2.5 10.8692 2.91407 10.8692 3.41465L11.3226 8.50704C11.3723 8.81604 11.7512 9.04471 12.2046 9.04471M9.44056 10.6392C9.67038 10.2436 9.65174 9.79868 9.4095 9.60091L5.20439 6.67156C4.76959 6.41817 4.1174 6.721 3.74471 7.36372C3.37203 8.00645 3.43414 8.72334 3.86894 8.97055L8.52748 11.1274C8.81942 11.2325 9.21074 11.0285 9.44056 10.633M14.9687 10.633C15.1985 11.0285 15.5898 11.2325 15.888 11.1274L20.5403 8.97055C20.9751 8.72334 21.0372 8.00645 20.6708 7.36372C20.2981 6.721 19.6397 6.41817 19.2049 6.67156L14.9998 9.60091C14.7637 9.79868 14.7451 10.2436 14.9749 10.6392M12.2046 15.3978C12.6643 15.3978 13.037 15.6389 13.0929 15.9417L13.5401 21.0341C13.5401 21.5347 12.95 21.9487 12.2046 21.9487C11.4593 21.9487 10.8692 21.5347 10.8692 21.0341L11.3226 15.9417C11.3723 15.6327 11.7512 15.404 12.2046 15.404M14.9687 13.8157C15.1985 13.4202 15.5898 13.2163 15.888 13.3213L20.5403 15.4782C20.9751 15.7254 21.0372 16.4484 20.6708 17.085C20.2981 17.7277 19.6397 18.0305 19.2049 17.7772L14.9998 14.8478C14.7637 14.65 14.7451 14.2051 14.9749 13.8157M9.44056 13.8157C9.67038 14.2051 9.65174 14.65 9.4095 14.8478L5.20439 17.7772C4.76959 18.0244 4.1174 17.7277 3.74471 17.085C3.37203 16.4423 3.43414 15.7254 3.86894 15.4782L8.52748 13.3213C8.81942 13.2163 9.21074 13.4202 9.44056 13.8157Z"
      fill="#FFC220"
    />
  </svg>
)

export default WalmartStore
