/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Badge, Collapse } from 'react-bootstrap'
import Tappable from 'react-tappable'
import classNames from 'classnames'
import Big from 'big.js'
import Image from 'components/image'
import { track, tracking } from 'lib/analytics'
import pluralize from 'pluralize'
import { ShoppingCartEmpty } from 'baby-design/icons'

import * as BLConstants from 'shared/constants'
import * as urls from 'lib/urls'
import { numberToCurrency } from 'lib/money'
import { Price } from 'shared/offer'
import { InfoButton } from 'components/buttons'

import * as api from 'cart/api'
import {
  buildCartItems,
  getRegistryDiscountSubtotal,
  getStores,
  getSubTotal,
  getTotalQuantity,
} from 'cart/lib'
import { RegistryDiscount } from 'cart/components/store-order-summary'
import EmptyCart from 'cart/components/empty-cart'

import css from './cart-summary.scss'
import PriceTag from 'baby-design/components/PriceTag/PriceTag'
import { CartSummary as CartSummaryTotals } from 'src/routes/(shop)/components/CartSummary'
import QuantifiableThumbnail from 'baby-design/components/QuantifiableThumbnail/QuantifiableThumbnail'

export const NUM_PREVIEW_ITEMS = 3

@tracking()
class CartSummary extends Component {
  constructor(props) {
    super(props)
    const useRegistryDiscount =
      props.cart.isEligibleForRegistryDiscount &&
      props.cart.applyRegistryDiscount
    this.state = {
      amazonCart: {},
      cart: {},
      items: buildCartItems(
        null,
        props.cart,
        props.reservations,
        null,
        useRegistryDiscount
      ),
      reservations: props.reservations,
      showCartDropdown: props.showCartDropdown || false,
      showEstTotalHint: false,
      useRegistryDiscount,
    }
    this.hideCartDropDownTimeoutId = null
    this.handleHideCartDropdown = this.handleHideCartDropdown.bind(this)
    this.handleShowCartDropdown = this.handleShowCartDropdown.bind(this)
    this.handleToggleShowEstTotalHint =
      this.handleToggleShowEstTotalHint.bind(this)
  }

  componentDidMount() {
    const { cart } = this.props
    this.fetchCart()
    PubSub.subscribe(BLConstants.UPDATE_CART_SUMMARY, (data) => {
      api.getCart().then((resp) => {
        const useRegistryDiscount =
          cart.isEligibleForRegistryDiscount && resp.cart.applyRegistryDiscount
        this.setState({
          amazonCart: resp.amazonCart,
          cart: resp.cart,
          items: buildCartItems(
            resp.amazonCart,
            resp.cart,
            resp.reservations,
            null,
            useRegistryDiscount
          ),
          reservations: resp.reservations,
          animateBadge: data && data.animateBadge,
          showCartDropdown: data && data.showCartDropdown,
          useRegistryDiscount,
        })

        this.autoHideTimeoutId = setTimeout(() => {
          this.setState({ showCartDropdown: false, animateBadge: false })
        }, 3000)
      })
    })
  }

  componentDidUpdate(prevProps) {
    const { showCartDropdown } = this.state
    const { activeMenu } = this.props
    if (
      showCartDropdown &&
      activeMenu !== prevProps.activeMenu &&
      activeMenu !== 'cart'
    ) {
      this.setState({ showCartDropdown: false, showEstTotalHint: false })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.hideCartDropDownTimeoutId)
    clearTimeout(this.autoHideTimeoutId)
  }

  handleHideCartDropdown() {
    this.hideCartDropDownTimeoutId = setTimeout(() => {
      this.setState({ showCartDropdown: false, showEstTotalHint: false })
    }, 500)
  }

  handleShowCartDropdown() {
    // Cancel the existing hide timers whenever the user hovers back over the component,
    // so that hiding the dropdown is less sensitive to mouse movements b/w the header
    // and the dropdown
    clearTimeout(this.hideCartDropDownTimeoutId)
    clearTimeout(this.autoHideTimeoutId)
    this.setState({ showCartDropdown: true })
    this.props.onMenuOpen()
  }

  handleToggleShowEstTotalHint() {
    const { showEstTotalHint } = this.state
    this.setState({ showEstTotalHint: !showEstTotalHint })
  }

  getReservationsPath() {
    const { registryUrlSlug } = this.props
    const { reservations } = this.state

    let path = reservations.length
      ? urls.reservationPath(reservations[0].token)
      : urls.reservationsPath
    if (registryUrlSlug) {
      path = `${path}?registry=${registryUrlSlug}`
    }
    return urls.absoluteUrl(path)
  }

  fetchCart = () => {
    api.getCart().then((resp) => {
      const { cart } = this.props
      const useRegistryDiscount =
        cart.isEligibleForRegistryDiscount && resp.cart.applyRegistryDiscount
      this.setState({
        amazonCart: resp.amazonCart,
        cart: resp.cart,
        items: buildCartItems(
          resp.amazonCart,
          resp.cart,
          resp.reservations,
          null,
          useRegistryDiscount
        ),
        reservations: resp.reservations,
        animateBadge: false,
        showCartDropdown: false,
        useRegistryDiscount,
      })
    })
  }

  @tracking((props, state, [eventCta]) => {
    const uniqueSaleTypes = [
      ...new Set(state.items.map((item) => item.saleType).filter(Boolean)),
    ]
    const payload = {
      event: track.cartOpened,
      eventCta,
      cartItems: state.items,
      ...(uniqueSaleTypes.length && { saleType: uniqueSaleTypes }),
    }

    return payload
  })
  trackCartOpened(e) {
    //
  }

  render() {
    const { disableDropdown, isSalesUIEnabled } = this.props
    const {
      amazonCart,
      animateBadge,
      cart,
      reservations,
      showCartDropdown,
      items,
      useRegistryDiscount,
      showEstTotalHint,
    } = this.state

    const badgeCount = getTotalQuantity(reservations, cart, amazonCart)
    const storeCount = getStores(reservations).length
    const ariaLabel = `${badgeCount} ${pluralize('item', badgeCount)} in cart`

    const registryDiscount = useRegistryDiscount
      ? getRegistryDiscountSubtotal(items)
      : Big(0)
    const subTotal = getSubTotal(items)

    const listHeader =
      badgeCount > 1
        ? `Your Cart - ${badgeCount} Items
        ${storeCount > 1 ? ` From ${storeCount} Stores` : ''}`
        : 'Your Cart'

    const showSalesEnabledUI = isSalesUIEnabled && reservations.length === 0

    const getPriceTagProps = (item) => {
      const { priceDetails } = item
      if (!priceDetails) {
        return {
          currentlyInActiveSale: false,
          callouts: [],
          currentPrice:
            useRegistryDiscount && item.registryDiscountPrice
              ? numberToCurrency(item.registryDiscountPrice, { unit: '' })
              : item.price,
        }
      }
      const { listPrice, msrp, salePrice, saleAttributes } = priceDetails
      const isInActiveSale =
        !useRegistryDiscount && saleAttributes?.saleType === 'active_sale'
      const currentPrice =
        useRegistryDiscount && item.registryDiscountPrice
          ? numberToCurrency(item.registryDiscountPrice, { unit: '' })
          : salePrice?.price || listPrice?.price || item.price
      return {
        ...(msrp?.price && { msrp: msrp.price }),
        ...(currentPrice && { currentPrice }),
        currentlyInActiveSale: isInActiveSale,
      }
    }

    return (
      <div
        className={classNames(css.CartSummary, 'btn-group', {
          open: showCartDropdown,
        })}
        onFocus={this.handleShowCartDropdown}
        onMouseLeave={this.handleHideCartDropdown}
        onMouseOver={this.handleShowCartDropdown}
      >
        <Tappable classes={{ active: css.buttonActive }}>
          <a
            aria-label={ariaLabel}
            className={classNames(css.CartSummary__button, {
              [css['CartSummary__button--withBadge']]: badgeCount > 0,
            })}
            href={this.getReservationsPath()}
            id="navbar-cart-link"
            rel="nofollow"
            onClick={this.trackCartOpened.bind(this, 'Button')}
          >
            <ShoppingCartEmpty className={css.cartIcon} />
            {badgeCount > 0 && (
              <Badge
                className={classNames('cart-badge', css.itemsCountBadge, {
                  bounce: animateBadge,
                })}
              >
                <span>{badgeCount}</span>
              </Badge>
            )}
          </a>
        </Tappable>
        {!disableDropdown && (
          <ul
            className={classNames(
              'animated animate-250ms dropdown-menu fadeIn hidden-xs',
              css.dropdownMenu
            )}
          >
            {items.length > 0 && (
              <>
                <li key="item-header" className="phm">
                  <div className={css.dropdownHeader}>{listHeader}</div>
                </li>
                {items.slice(0, NUM_PREVIEW_ITEMS).map((item, index) => {
                  return (
                    <Fragment key={`preview-${item.productId || item.uuid}`}>
                      <li key={`item-separator-${item.productId || item.uuid}`}>
                        <hr
                          className="mam"
                          key={`hr-${item.productId || item.uuid}`}
                        />
                      </li>
                      <li
                        className="pam"
                        key={`li-cart-item-${item.productId || item.uuid}`}
                      >
                        <div
                          className="cart-item"
                          style={{ display: 'flex' }}
                          key={`cart-item-${item.productId || item.uuid}`}
                        >
                          <div
                            className="prl"
                            key={`cart-item-image-${item.productId || item.uuid}`}
                          >
                            {showSalesEnabledUI ? (
                              <QuantifiableThumbnail
                                imageAlt={item.name}
                                imageSrc={item.imageUrl}
                                quantity={item.quantity}
                                size="md"
                                key={`thumbnail-${item.productId || item.uuid}`}
                              />
                            ) : (
                              <Image
                                height={100}
                                src={item.imageUrl}
                                role="presentation"
                                width={100}
                                key={`image-${item.productId || item.uuid}`}
                              />
                            )}
                          </div>
                          <div key={`quantity-${item.productId || item.uuid}`}>
                            <ul
                              style={
                                showSalesEnabledUI
                                  ? { maxWidth: '250px' }
                                  : { maxWidth: '160px' }
                              }
                              className="list-unstyled"
                              key={`ul-${item.productId || item.uuid}`}
                            >
                              <li
                                className="mbs"
                                key={`li-item-name-${item.productId || item.uuid}`}
                              >
                                <div
                                  className="h6 mvn"
                                  key={`item-name-${item.productId || item.uuid}`}
                                >
                                  {item.name}
                                </div>
                              </li>
                              {item.price || item.quantity > 1 ? (
                                <li
                                  key={`li-offer-${item.productId || item.uuid}`}
                                >
                                  <div
                                    className="offer-layout-wrapper"
                                    key={`offer-${item.productId || item.uuid}`}
                                  >
                                    {!showSalesEnabledUI &&
                                    item.isPurchasableOnBabylist ? (
                                      <span
                                        className="icon-offer icon-offer-babylist mrs"
                                        key={`bl-icon-${item.productId || item.uuid}`}
                                      />
                                    ) : null}
                                    {item.type ===
                                      BLConstants.CONFIG.cart
                                        .typeAmazonCart && (
                                      <span
                                        className="icon-offer icon-offer-amazon mrs"
                                        key={`amazon-icon-${item.productId || item.uuid}`}
                                      />
                                    )}
                                    <span
                                      className="mvn offer-price offer-price-without-store"
                                      key={`offer-without-store-${item.productId || item.uuid}`}
                                    >
                                      {item.price &&
                                        (showSalesEnabledUI ? (
                                          <PriceTag
                                            {...getPriceTagProps(item)}
                                            size="sm"
                                            className={css.priceTag}
                                          />
                                        ) : (
                                          <Price
                                            className="text-bold"
                                            discountedPrice={
                                              item.registryDiscountPrice
                                            }
                                            discountedPriceFirst
                                            price={item.price}
                                          />
                                        ))}
                                      {!showSalesEnabledUI && (
                                        <Fragment
                                          key={`quantity-${item.productId || item.uuid}`}
                                        >
                                          {item.price && item.quantity > 1
                                            ? ' '
                                            : null}
                                          {item.quantity > 1
                                            ? `x ${item.quantity}`
                                            : null}
                                        </Fragment>
                                      )}
                                    </span>
                                  </div>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      </li>
                    </Fragment>
                  )
                })}
                {items.length > NUM_PREVIEW_ITEMS && (
                  <>
                    <hr className="mam" key="view-more-separator" />
                    <li className="pam text-center" key="view-more-item">
                      <div>
                        <a
                          className="more pan"
                          href={this.getReservationsPath()}
                          onClick={this.trackCartOpened.bind(this, 'More')}
                        >
                          <div className="h6 man">{`+${
                            items.length - NUM_PREVIEW_ITEMS
                          } More ${pluralize(
                            'Item',
                            items.length - NUM_PREVIEW_ITEMS
                          )}`}</div>
                        </a>
                      </div>
                    </li>
                  </>
                )}
                <li key="est-total-separator">
                  <hr className="mvm" />
                </li>
                {showSalesEnabledUI && (
                  <li key="discounts-and-totals">
                    <CartSummaryTotals isCompact />
                  </li>
                )}
                {!showSalesEnabledUI &&
                  useRegistryDiscount &&
                  registryDiscount > 0 && (
                    <li
                      className="phm ptm text-center"
                      key="registry-discount-item"
                    >
                      <RegistryDiscount
                        amount={registryDiscount}
                        className="mvn text-left"
                      />
                    </li>
                  )}
                {!showSalesEnabledUI && (
                  <li className="pam" key="est-total-item">
                    <div>
                      <a
                        className="inline-block pan"
                        onClick={this.handleToggleShowEstTotalHint}
                      >
                        <div className="h6 man">Estimated Total</div>
                      </a>
                      <div className="h6 mvn pull-right">
                        <strong>
                          {numberToCurrency(subTotal - registryDiscount)}
                        </strong>
                      </div>
                    </div>
                    <Collapse in={showEstTotalHint}>
                      <div>
                        <p className="h7 ptm">
                          Prices vary by store, and do not include applicable
                          tax or shipping.
                        </p>
                      </div>
                    </Collapse>
                  </li>
                )}
                <li className="pam" key="view-all-item">
                  <div>
                    <InfoButton
                      className="mhn"
                      href={this.getReservationsPath()}
                      block
                      pill
                      onClick={this.trackCartOpened.bind(
                        this,
                        'Continue to Cart'
                      )}
                    >
                      Continue to Cart
                    </InfoButton>
                  </div>
                </li>
              </>
            )}
            {!items.length && (
              <li className="pam mvl" key="empty-cart-item">
                <EmptyCart />
              </li>
            )}
          </ul>
        )}
      </div>
    )
  }
}

CartSummary.propTypes = {
  cart: PropTypes.object.isRequired,
  disableDropdown: PropTypes.bool,
  registryUrlSlug: PropTypes.string,
  reservations: PropTypes.array.isRequired,
  showCartDropdown: PropTypes.bool,
  activeMenu: PropTypes.string,
  onMenuOpen: PropTypes.func,
}

CartSummary.defaultProps = {
  disableDropdown: false,
  registryUrlSlug: null,
  showCartDropdown: false,
}

export default CartSummary
