import * as blUrls from 'lib/urls'
import * as BLConstants from 'shared/constants'

export const guidesMenu = (guideTopics) => {
  let guideTopicMenuItems = []

  if (guideTopics) {
    guideTopicMenuItems = guideTopics.items.map((topic) => ({
      title: topic.name,
      href: blUrls.helloBabyPostPath(topic.slug),
    }))
  }

  return {
    title: 'Guides',
    subMenuItems: [
      {
        title: 'Home',
        href: blUrls.helloBabyPath,
      },
      ...guideTopicMenuItems,
    ],
  }
}

export const healthMenu = {
  title: 'Health',
  size: 'large',
  href: blUrls.healthPath,
  external: true,
}

export const homeMenu = (currentUser) => ({
  title: 'Home',
  size: 'large',
  href: currentUser ? blUrls.registryHomePath : '/',
})

export const loggedInRegistrySubMenu = (currentUser) => {
  const links = [
    {
      title: 'My Babylist',
      href: blUrls.registryPath(currentUser?.registryUrlSlug),
    },
    {
      title: 'Share Registry',
      href: blUrls.shareRegistryPath,
    },
    {
      title: 'Checklist',
      href: blUrls.registryChecklistPath,
    },
    {
      title: 'Gift Tracker',
      href: blUrls.giftTrackerPath(),
    },
    {
      title: 'Transfer Another Registry',
      href: blUrls.registryExternalPath,
    },
    {
      title: 'Settings',
      href: blUrls.settingsPath,
    },
  ]
  if (currentUser && currentUser?.hasMultipleRegistries) {
    links.push({
      title: 'Switch Registry',
      href: blUrls.listsPath,
    })
  }

  return links
}

export const loggedInUserMenu = () => [
  {
    title: 'Your Orders',
    href: blUrls.settingsYourOrdersPath,
    size: 'small',
  },
  {
    title: 'Settings',
    href: blUrls.settingsPath,
    size: 'small',
  },
  {
    title: 'Log Out',
    size: 'small',
    href: blUrls.logoutPath,
  },
]

export const loggedOutRegistrySubMenu = () => [
  {
    title: 'Why Babylist?',
    href: blUrls.whyBabyListPath,
  },
  {
    title: 'Create Registry',
    href: blUrls.newRegistryPath,
  },
  {
    title: 'Find Registry',
    href: blUrls.findPath,
  },
  {
    title: 'Baby Registry Checklist',
    href: blUrls.checklistPath,
  },
]

export const productsSubMenu = (productCategories) => {
  // exclude sale preview categories from nav
  const filteredCategories = productCategories.filter(
    (category) =>
      !BLConstants.SALE_PREVIEW_CATEGORY_SLUGS.includes(category.urlSlug)
  )

  const result = filteredCategories.map((productCategory) => ({
    href: blUrls.storeCategoryPath(productCategory.urlSlug),
    title: productCategory.title,
  }))

  return [
    {
      href: blUrls.storePath,
      title: 'Home',
    },
    ...result,
  ]
}

export const productsMenu = (productCategories) => ({
  title: 'Shop',
  subMenuItems: productsSubMenu(productCategories),
})

export const registryMenu = (currentUser) => ({
  title: 'Registry',
  subMenuItems: currentUser
    ? loggedInRegistrySubMenu(currentUser)
    : loggedOutRegistrySubMenu(),
})

export const userMenu = (currentUser) => (currentUser ? loggedInUserMenu() : [])

export const aboutUsMenu = {
  title: 'About Us',
  subMenuItems: [
    {
      title: 'Team',
      href: blUrls.teamPath,
    },
    {
      title: 'Jobs',
      href: blUrls.jobsPath,
    },
    {
      title: 'Our Story',
      href: blUrls.aboutPath,
    },
    {
      title: 'Babylist Health Advisory Board',
      href: blUrls.healthAdvisoryBoardPath,
    },
    {
      title: 'Experiences',
      href: blUrls.experiencesPath,
    },
    {
      title: 'Press',
      href: blUrls.pressPath,
    },
    {
      title: 'Terms of Use',
      href: blUrls.termsOfUsePath,
    },
    {
      title: 'Privacy Policy',
      href: blUrls.privacyPolicyPath,
    },
    {
      title: 'Cookie Notice',
      href: blUrls.cookiesPath,
    },
    {
      title: 'CCPA Notice',
      href: blUrls.ccpaPath,
    },
  ],
}
