import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import css from './ScrollTabs.styles.scss'

import { ScrollTabsProps } from './ScrollTabs.types'

const tabTypeClassname = (type: ScrollTabsProps<React.Key>['type']) => {
  switch (type) {
    case 'primary':
      return css.tab__primary
    case 'secondary':
      return css.tab__secondary
  }
}

const tabVariantClassname = (
  variant: ScrollTabsProps<React.Key>['variant']
) => {
  switch (variant) {
    case 'horizontal':
      return css.tab__horizontal
    case 'vertical':
      return css.tab__vertical
  }
}

function ScrollTabs<KeyType extends React.Key>({
  variant,
  type,
  tabs,
  selectedTabKey,
  onClick: handleClick = () => {},
  className,
}: ScrollTabsProps<KeyType>) {
  const ScrollTabsClasses = classNames(
    css.tabs,
    tabTypeClassname(type),
    tabVariantClassname(variant),
    tabs.length === 0 && css.shimmer
  )

  const tabRef = useRef<HTMLButtonElement>(null)
  const emptyUnderlineData = {
    width: 0,
    offsetLeft: 0,
    height: 0,
    offsetTop: 0,
  }
  const [selectUnderlineData, setSelectUnderlineData] =
    useState(emptyUnderlineData)

  const tablistRef = useRef<HTMLDivElement>(null)

  const updateUnderlinePosition = () => {
    if (tabRef.current) {
      const { offsetLeft, offsetTop, offsetWidth, offsetHeight } =
        tabRef.current
      setSelectUnderlineData({
        width: offsetWidth,
        offsetLeft: offsetLeft,
        height: offsetHeight,
        offsetTop: offsetTop,
      })
    } else {
      setSelectUnderlineData(emptyUnderlineData)
    }
  }

  useEffect(() => {
    updateUnderlinePosition()
  }, [tabs, variant, selectedTabKey])

  useEffect(() => {
    const observer = new ResizeObserver((_) => {
      updateUnderlinePosition()
    })
    tablistRef.current && observer.observe(tablistRef.current)
    return () => {
      tablistRef.current && observer.unobserve(tablistRef.current)
    }
  }, [])

  const selectUnderlineStyle =
    variant === 'horizontal'
      ? {
          width: `${selectUnderlineData.width}px`,
          left: `${selectUnderlineData.offsetLeft}px`,
        }
      : {
          height: `${selectUnderlineData.height - 20}px`,
          top: `${selectUnderlineData.offsetTop + 10}px`,
        }

  return (
    <div className={classNames(css.tab__overflow, className)}>
      <div className={ScrollTabsClasses}>
        <div className={css.tabs__tablist} ref={tablistRef}>
          {tabs.map((tab) => {
            const isSelected = tab.key === selectedTabKey
            const onClick =
              tab.onClick ||
              ((event) => handleClick({ clickedTabKey: tab.key, event: event }))

            return (
              <button
                key={tab.key}
                ref={isSelected ? tabRef : null}
                className={css.tab}
                aria-current={isSelected && 'page'}
                onClick={(e) => {
                  e.currentTarget.blur()
                  onClick(e)
                }}
              >
                <div className={css.tab__wrapper}>
                  <div className={css.tab__label}>{tab.label}</div>
                </div>
              </button>
            )
          })}
          <div
            className={css.tab__selected_underline}
            style={selectUnderlineStyle}
          />
        </div>
        <div className={css.tab__tablist_underline} />
      </div>
    </div>
  )
}

export default ScrollTabs
