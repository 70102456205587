import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import Close from '../../icons/Close.svg'
import IconButton from '../IconButton/IconButton'

import css from './Modal.styles.scss'

import { ModalProps } from './Modal.types'

const Modal: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  className,
  children,
  showCloseButton = false,
  primaryButton,
  secondaryButton,
  additionalLink,
  icon,
  scrollToTopOnContentChange = false,
  alignButton,
}) => {
  const showActions = primaryButton || secondaryButton || additionalLink
  const [isVisible, setIsVisible] = useState(false)

  const ModalClasses = classNames(
    css.Modal,
    className,
    !showActions && css['Modal--noActions'],
    additionalLink
      ? css['Modal--additionalLink']
      : css['Modal--noAdditionalLink'],
    alignButton == 'right' ? css['Modal--rightAlignButton'] : '',
    alignButton == 'left' ? css['Modal--leftAlignButton'] : ''
  )

  useEffect(() => {
    if (!isOpen) return

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose()
      }
    }
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [isOpen])

  useEffect(() => {
    // De-couple isVisible state from isOpen to allow for animation
    setIsVisible(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (scrollToTopOnContentChange) {
      const content = document.querySelector(`.${css.Modal__content}`)
      if (content) {
        content.scrollTo(0, 0)
      }
    }
  }, [children])

  return (
    <>
      {isOpen && (
        <div
          className={classNames(css['Modal--backdrop'], {
            [css['open']]: isVisible,
          })}
          onClick={handleClose}
        />
      )}
      {isOpen && (
        <div className={ModalClasses}>
          <div className={css.Modal__wrapper}>
            <div className={css.Modal__content}>
              {showCloseButton && (
                <IconButton
                  className={css.Modal__close}
                  onClick={handleClose}
                  variant="standard-default"
                  size="sm"
                  data-testid="closeButton"
                  aria-label="Close modal"
                >
                  <Close />
                </IconButton>
              )}
              {icon && (
                <header>
                  <div className={css.Modal__icon}>{icon}</div>
                </header>
              )}
              {children}
            </div>
            {showActions && (
              <div className={css.Modal__actions}>
                {additionalLink ? (
                  <>
                    <div className={css.Modal__additionalLink}>
                      {additionalLink}
                    </div>
                    <div className={css.Modal__buttons}>
                      {secondaryButton}
                      {primaryButton}
                    </div>
                  </>
                ) : (
                  <div className={css.Modal__buttons}>
                    {secondaryButton}
                    {primaryButton}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Modal
