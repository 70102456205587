import { track } from 'lib/analytics'
import { storeSearchResultsPath, helloBabySearchPath } from 'lib/urls'
import { ResultType, SearchResult, SectionHeading } from './types'
import {
  SEARCH_HISTORY_TYPE_GUIDES,
  SEARCH_HISTORY_TYPE_PRODUCTS,
  SEARCH_HISTORY_TYPE_REGISTRIES,
  SearchHistoryType,
  addToSearchHistory,
} from 'shared/utils/searchHistory/searchHistory'

export const getSearchResultsUrl = (
  eventLocation: string,
  query: string,
  searchResults: SearchResult[] = []
) => {
  const firstSearchResultsHeader = getFirstSearchResultsHeader(searchResults)
  if (firstSearchResultsHeader) {
    const url = (firstSearchResultsHeader as SectionHeading).url
    const searchTerm = new URLSearchParams(url).get('search_term')
    if (searchTerm != encodeURIComponent(query)) {
      return replaceSearchTerm(url, query)
    }
    return url
  }

  if (eventLocation === track.EventLocation.GUIDE) {
    return helloBabySearchPath(query)
  }

  return storeSearchResultsPath(query)
}

export const getFirstSearchResultsHeader = (
  searchResults: SearchResult[]
): SectionHeading => {
  return searchResults.find(
    (result: SearchResult) =>
      result.type === ResultType.SECTION_HEADING &&
      (result as SectionHeading).url
  ) as SectionHeading
}

export const getSearchHistoryTypeFromSectionHeading = (
  sectionHeading: SectionHeading
): SearchHistoryType | undefined => {
  switch (sectionHeading?.title) {
    case 'Guides':
      return SEARCH_HISTORY_TYPE_GUIDES
    case 'Products':
      return SEARCH_HISTORY_TYPE_PRODUCTS
    case 'Registries':
      return SEARCH_HISTORY_TYPE_REGISTRIES
  }
}

export const getSearchTerm = (): string => {
  if (typeof window === 'undefined') return ''

  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('search_term') || ''
}

export const pushToSearchHistory = (
  searchResults: SearchResult[],
  searchQuery: string
) => {
  const firstSearchResultsHeader = getFirstSearchResultsHeader(searchResults)
  const searchQueryType = getSearchHistoryTypeFromSectionHeading(
    firstSearchResultsHeader
  )
  if (searchQueryType) {
    addToSearchHistory(searchQuery, searchQueryType)
  }
}

export const replaceSearchTerm = (url: string, newSearchTerm: string) => {
  const urlObj = new URL(url)
  urlObj.searchParams.set('search_term', newSearchTerm)
  return urlObj.toString()
}
