import React from 'react'
import classNames from 'classnames'

import css from './RadioButton.styles.scss'

import { RadioButtonProps } from './RadioButton.types'

const radioButtonSizeClassname = (size: RadioButtonProps['size']) => {
  switch (size) {
    case 'sm':
      return css.radio_button__small
    case 'md':
      return css.radio_button__medium
    case 'lg':
      return css.radio_button__large
  }
}

const radioButtonTypeClassname = (type: RadioButtonProps['type']) => {
  switch (type) {
    case 'primary':
      return css.radio_button__primary
    case 'secondary':
      return css.radio_button__secondary
  }
}

const RadioButton: React.FC<RadioButtonProps> = ({
  type = 'primary',
  size,
  className,
  invalid = false,
  ...props
}) => {
  const RadioButtonClasses = classNames(
    css.radio_button__wrapper,
    radioButtonTypeClassname(type),
    radioButtonSizeClassname(size),
    {
      [css.radio_button__invalid]: invalid,
    },
    className
  )

  return (
    <span className={RadioButtonClasses}>
      <input
        type="radio"
        {...props}
        className={classNames(css.radio_button__reset, css.radio_button__input)}
      />
      <div className={css.radio_button__dot_wrapper} aria-hidden="true">
        <div className={css.radio_button__dot} />
      </div>
    </span>
  )
}

export default RadioButton
