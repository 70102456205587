import React from 'react'
import classNames from 'classnames'

import css from './NavLink.styles.scss'

import { NavLinkProps } from './NavLink.types'

const NavLink: React.FC<NavLinkProps> = ({
  variant = 'default',
  size = 'md',
  disabled = false,
  className,
  innerContentClassName,
  children,
  icon,
  underline = false,
  weight = 'medium',
  onClick = () => {},
  ...props
}) => {
  const NavLinkClasses = classNames(
    css.NavLink,
    css[`NavLink--size-${size}-${weight}`],
    css[`NavLink--style-${variant}`],
    disabled && css['NavLink--disabled'],
    className
  )

  return (
    <a
      className={NavLinkClasses}
      aria-disabled={disabled}
      onClick={(e) => {
        e.currentTarget.blur()
        onClick(e)
      }}
      {...props}
    >
      {icon?.side === 'left' && icon.icon}
      <div
        className={classNames(
          css.NavLink__label,
          underline && css['NavLink__label--underline'],
          innerContentClassName
        )}
      >
        {children}
      </div>
      {icon?.side === 'right' && icon.icon}
    </a>
  )
}

export default NavLink
