import PropTypes from 'prop-types'
import { filter, find, map } from 'lodash'
import { filterPostsByTopicSlug } from '../../lib'
import EmailCallout from 'components/email-callout'
import PregnancyWeekSelect from '../pregnancy-week-select'
import TopicSection from '../TopicSection'
import { PREGNANCY_WEEK_BY_WEEK_SLUG } from '../../constants'
import css from './pregnancy-view.scss'

const NUM_VISIBLE_POSTS = 5

const PregnancyView = ({ posts, subTopics, emailUrls }) => {
  let firstTrimester = find(posts, { slug: 'first-trimester' })
  let secondTrimester = find(posts, { slug: 'second-trimester' })
  let thirdTrimester = find(posts, { slug: 'third-trimester' })
  let pregnancyWeekByWeek = find(subTopics, {
    slug: PREGNANCY_WEEK_BY_WEEK_SLUG,
  })
  let weekByWeekFeaturedPost = {
    title: pregnancyWeekByWeek.pageTitle,
    featuredImage: { url: pregnancyWeekByWeek.image },
    slug: pregnancyWeekByWeek.slug,
    teaser: pregnancyWeekByWeek.description,
  }
  let weekByWeekPosts = filterPostsByTopicSlug(
    posts,
    PREGNANCY_WEEK_BY_WEEK_SLUG,
    { sortBy: 'title', order: 'asc' }
  )
  let topicSlugsToNotShowArticles = [
    'first-trimester-of-pregnancy',
    'second-trimester-of-pregnancy',
    'third-trimester-of-pregnancy',
    PREGNANCY_WEEK_BY_WEEK_SLUG,
  ]

  subTopics = filter(subTopics, (topic) => {
    return topicSlugsToNotShowArticles.indexOf(topic.slug) < 0
  })

  return (
    <div className={css.pregnancyContainer}>
      <PregnancyWeekSelect
        posts={weekByWeekPosts}
        className={'inline-block mbn pull-right hidden-xs'}
      />
      <TopicSection
        showTopics={false}
        showAllButton={true}
        topic={{
          name: pregnancyWeekByWeek.name,
          slug: pregnancyWeekByWeek.slug,
        }}
        posts={[
          weekByWeekFeaturedPost,
          firstTrimester,
          secondTrimester,
          thirdTrimester,
        ]}
        weekByWeekPosts={weekByWeekPosts}
      />
      {map(subTopics, (topic, idx) => {
        let filteredPosts = filterPostsByTopicSlug(posts, topic.slug)
        return (
          <TopicSection
            key={'topic_section_' + topic.slug}
            showTopics={false}
            showAllButton={filteredPosts.length > NUM_VISIBLE_POSTS}
            topic={{ name: topic.name, slug: topic.slug }}
            teaserText={topic.description}
            posts={filteredPosts.slice(0, NUM_VISIBLE_POSTS)}
            hideLastPostSm
          />
        )
      })}
      <EmailCallout
        emailType={BLConstants.EMAIL_USER_PREGNANCY}
        emailUrlsByWeek={emailUrls}
      />
    </div>
  )
}

PregnancyView.propTypes = {
  posts: PropTypes.object,
  emailUrls: PropTypes.object.isRequired,
}

export default PregnancyView
