import css from './DiscountItem.styles.scss'
import classnames from 'classnames'

export const DiscountItem = ({
  label,
  amount,
  isCompact,
}: {
  label: string
  amount: string
  isCompact?: boolean
}) => (
  <div
    className={classnames(css.DiscountItem, {
      [css['DiscountItem--compact']]: isCompact,
    })}
  >
    {isCompact ? <span>{label}</span> : <span>- {label}</span>}
    <span>-${amount}</span>
  </div>
)
