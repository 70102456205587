import { CartAPI } from 'src/routes/(shop)/api/cart'
import { CartSummary as Component } from './CartSummary'

interface CartSummaryProps {
  isCompact?: boolean
}

export const CartSummary = ({ isCompact }: CartSummaryProps) => {
  const { data, isLoading, error } = CartAPI.useShow()

  if (isLoading) return null
  if (error) return <div>Error loading cart data</div>
  if (!data || !data.checkoutTotals) return null

  return <Component isCompact={isCompact} {...data} />
}
