import { FC } from 'react'
import { TextLink } from 'baby-design'
import { LinkOut } from 'baby-design/icons'
import { healthPath, helloBabyPath, indexPath, storePath } from 'lib/urls'
import ToggleNav from '../ToggleNav'
import css from '../../global-nav.scss'

interface HomePageSiteNavSectionProps {
  activeNavItem?: 'Home' | 'Registry' | 'Store' | 'Guides'
  className?: string
  handleToggleNav: () => void
  trackClick: (eventCta: string) => void
}

const HomePageSiteNavSection: FC<HomePageSiteNavSectionProps> = ({
  activeNavItem,
  className,
  handleToggleNav,
  trackClick,
}) => (
  <ul className={className}>
    <ToggleNav onClick={handleToggleNav} />
    <li className={css.fullNavOnly}>
      <TextLink
        href={indexPath}
        isSelected={activeNavItem === 'Registry'}
        size="md"
        weight={activeNavItem === 'Registry' ? 'medium' : 'regular'}
        onClick={() => trackClick('Registry')}
      >
        Registry
      </TextLink>
    </li>
    <li className={css.fullNavOnly}>
      <TextLink
        href={storePath}
        isSelected={activeNavItem === 'Store'}
        size="md"
        weight={activeNavItem === 'Store' ? 'medium' : 'regular'}
        onClick={() => trackClick('Shop')}
      >
        Shop
      </TextLink>
    </li>
    <li className={css.fullNavOnly}>
      <TextLink
        href={helloBabyPath}
        isSelected={activeNavItem === 'Guides'}
        size="md"
        weight={activeNavItem === 'Guides' ? 'medium' : 'regular'}
        onClick={() => trackClick('Guides')}
      >
        Guides
      </TextLink>
    </li>
    <li className={css.fullNavOnly}>
      <TextLink
        href={healthPath}
        icon={{
          side: 'right',
          icon: <LinkOut />,
        }}
        rel="noreferrer"
        size="md"
        target="_blank"
        weight="regular"
        onClick={() => trackClick('Health')}
      >
        Health
      </TextLink>
    </li>
  </ul>
)

export default HomePageSiteNavSection
