import { useRef, useState } from 'react'
import SearchBarInput from './SearchBarInput/SearchBarInput'
import SearchResults from './SearchResults/SearchResults'
import useSearch from './api/useSearch'
import useClickAway from 'registry/components/useClickAway/useClickAway'
import Overlay from 'components/global-nav/Overlay'
import { unifiedSearchPopularSearches } from './constants'
import {
  getSearchResultsUrl,
  getSearchTerm,
  pushToSearchHistory,
} from './helpers'
import {
  lockBodyScroll,
  unlockBodyScroll,
} from 'shared/utils/lockBodyScroll/lockBodyScroll'

interface DesktopSearchBarProps {
  className?: string
  eventLocation?: string
  isLoggedIn: boolean
}

const DesktopSearchBar = ({
  className = '',
  eventLocation = '',
  isLoggedIn,
}: DesktopSearchBarProps) => {
  const [showResults, setShowResults] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const { searchQuery, searchResults, updateSearchQuery, clearSearchQuery } =
    useSearch(getSearchTerm(), eventLocation, showResults)

  const openModal = () => {
    setShowResults(true)
    lockBodyScroll('unified-search-bar')
  }

  const closeModal = () => {
    setShowResults(false)
    inputRef.current?.blur()
    unlockBodyScroll('unified-search-bar')
  }

  const handleOnFocus = () => {
    openModal()
  }

  const handleClickAway = () => {
    closeModal()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      closeModal()
    }
    if (e.key === 'Enter') {
      pushToSearchHistory(searchResults, searchQuery)

      window.location.href = getSearchResultsUrl(
        eventLocation,
        searchQuery,
        searchResults
      )
    }
  }

  const clickAwayRef = useClickAway<HTMLDivElement>(handleClickAway)

  return (
    <>
      <div className={className} ref={clickAwayRef} onKeyDown={handleKeyDown}>
        <SearchBarInput
          inputRef={inputRef}
          query={searchQuery}
          setQuery={updateSearchQuery}
          clearQuery={clearSearchQuery}
          onFocus={handleOnFocus}
          isLoggedIn={isLoggedIn}
        />
        {showResults && (
          <SearchResults
            searchResults={
              searchQuery?.length == 0 || searchResults?.length == 0
                ? unifiedSearchPopularSearches
                : searchResults
            }
            setQuery={updateSearchQuery}
            searchQuery={searchQuery}
          />
        )}
      </div>
      <Overlay show={showResults} />
    </>
  )
}

export default DesktopSearchBar
