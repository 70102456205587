import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import css from './RightDrawer.styles.scss'

import { RightDrawerProps } from './RightDrawer.types'
import Close from '../../icons/Close.svg'
import { IconButton } from '../..'
import { Chevron } from '../../icons'

const RightDrawer: React.FC<RightDrawerProps> = ({
  children,
  className,
  mainIcon = 'close',
  contextualIcon,
  handleClose,
  isOpen,
  title,
}) => {
  const RightDrawerClasses = classNames(css.RightDrawer, className, {
    [css['RightDrawer--open']]: isOpen,
  })
  return (
    <div
      className={RightDrawerClasses}
      data-testid="root"
      onClick={handleClose}
      tabIndex={-1}
    >
      <div
        className={classNames(css.RightDrawer__drawer, {
          [css['RightDrawer__drawer--open']]: isOpen,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={css.RightDrawer__header}>
          <div className={css.RightDrawer__navigationButtonContainer}>
            <IconButton
              aria-label={mainIcon == 'close' ? 'Close' : 'Back'}
              className={css.RightDrawer__navigationButton}
              data-testid="close-button"
              onClick={handleClose}
              onKeyDown={(e) => {
                if (e.key === ' ' || e.key === 'Enter') handleClose()
              }}
              size="xs"
              type="button"
              variant="standard-default"
            >
              {mainIcon == 'close' ? (
                <Close className={css.RightDrawer__navigationIcon} />
              ) : (
                <Chevron
                  className={classNames(
                    css.RightDrawer__navigationIcon,
                    css.RightDrawer__navigationIcon_chevron
                  )}
                />
              )}
            </IconButton>
          </div>
          <div className={css.RightDrawer__titleContainer}>
            <h5 className={css.RightDrawer__title}>{title}</h5>
          </div>
          <div>{contextualIcon}</div>
        </div>
        <div className={css.RightDrawer__contentContainer}>{children}</div>
      </div>
    </div>
  )
}

const RightDrawerWrapper: React.FC<RightDrawerProps> = (props) => {
  const { isOpen, handleDismount = () => {} } = props
  const [isMounted, setIsMounted] = useState(isOpen)
  const [internalIsOpen, setInternalIsOpen] = useState(false)

  useEffect(() => {
    // Decouple mounting and opening to allow the css transition to trigger
    if (isOpen) {
      setIsMounted(true)
      const timeoutId = setTimeout(() => {
        setInternalIsOpen(true)
      }, 50)

      return () => clearTimeout(timeoutId)
    } else if (internalIsOpen) {
      setInternalIsOpen(false)
      const timeoutId = setTimeout(() => {
        setIsMounted(false)
        handleDismount()
      }, 350)

      return () => clearTimeout(timeoutId)
    }
  }, [isOpen])

  const updatedProps = {
    ...props,
    isOpen: internalIsOpen,
  }
  // Tab lock relies on component being unmounted when closed
  return isMounted ? <RightDrawer {...updatedProps} /> : null
}

export default RightDrawerWrapper
