import Clickable from 'components/clickable'
import * as urls from 'lib/urls'
import css from './banner.scss'
// This promo will be live sometime Jun 2023
// Please check this slack channel for more details #tmp-bbby-promo-banner
const PromotionBanner = () => (
  <Clickable url={urls.storePath}>
    <div className={css.infoBanner}>
      <div className="container">
        <div className={css.flexRow}>
          <div className={css.middle}>
            Say hello to Babylist! Get<strong> 15% off </strong> your first
            order with the code <strong>BUYBUYBABY</strong>. Shop now.{' '}
            <a className={css.small} href={urls.promotionTermsPath}>
              DETAILS
            </a>
          </div>
        </div>
      </div>
    </div>
  </Clickable>
)

export default PromotionBanner
