import React, { ChangeEvent } from 'react'
import { PaymentPreferenceTextFieldProps } from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/PaymentPreferenceTextField/PaymentPreferenceTextField.types'
import { Input } from '../../../../../../../../../baby-design'
import css from './PaymentPreferenceTextField.styles.scss'

const PaymentPreferenceTextField: React.FC<PaymentPreferenceTextFieldProps> = ({
  errorMessage,
  label,
  message,
  name,
  onValueChanged,
  placeholder,
  prefix,
  required = false,
  value,
}) => {
  const handleValueChanged = (e: ChangeEvent<HTMLInputElement>) =>
    onValueChanged?.(e.target.value)

  const fieldLabel = (
    <>
      {label}{' '}
      {!required && (
        <span className={css.PaymentPreferenceTextField__OptionalLabel}>
          (optional)
        </span>
      )}
    </>
  )

  return (
    <div className={css.PaymentPreferenceTextField__Container}>
      <Input
        label={fieldLabel}
        name={name}
        placeholder={placeholder}
        prefix={prefix}
        size="md"
        status={
          errorMessage ? { type: 'error', message: errorMessage } : undefined
        }
        value={value}
        onChange={handleValueChanged}
      />
      {message && (
        <p className={css.PaymentPreferenceTextField__Message}>{message}</p>
      )}
    </div>
  )
}

export default PaymentPreferenceTextField
