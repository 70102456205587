import React from 'react'
import classNames from 'classnames'

import css from './LoadingDots.styles.scss'

import { LoadingDotsProps } from './LoadingDots.types'

const LoadingDots: React.FC<LoadingDotsProps> = ({
  className,
  ariaHidden = false,
}) => {
  const LoadingDotsClasses = classNames(css.LoadingDots, className)

  return (
    <div
      className={LoadingDotsClasses}
      role="status"
      aria-label="Loading"
      aria-hidden={ariaHidden}
    >
      <div className={css.loading_dot} />
      <div className={css.loading_dot} />
      <div className={css.loading_dot} />
    </div>
  )
}

export default LoadingDots
