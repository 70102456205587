import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/buttons'
import { errorMessage, successMessage } from 'lib/flash-message'
import * as api from 'store/api'
import css from './styles.scss'

const InStockAlertButton = ({ buttonStyle, productId, text }) => {
  const [addingInStockAlert, setAddingInStockAlert] = useState(false)

  const handleClick = () => {
    setAddingInStockAlert(true)
    api
      .addProductAvailabilitySubscription(productId)
      .then(() => {
        successMessage("We'll email you when this item is back in stock!")
        setAddingInStockAlert(false)
      })
      .catch(() => {
        errorMessage()
        setAddingInStockAlert(false)
      })
  }

  return (
    <Button
      block
      empty
      className={css.root}
      {...buttonStyle}
      submitting={addingInStockAlert}
      onClick={handleClick}
    >
      {text}
    </Button>
  )
}

InStockAlertButton.propTypes = {
  buttonStyle: PropTypes.object,
  productId: PropTypes.number.isRequired,
  text: PropTypes.string,
}

InStockAlertButton.defaultProps = {
  text: 'Get in stock alert',
}

export default InStockAlertButton
