import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Big from 'big.js'
import pluralize from 'pluralize'

import * as BLConstants from 'shared/constants'
import { numberToCurrency } from 'lib/money'
import { ShippingDisclaimer } from 'store/components/landing-page/disclaimers'
import CheckoutButton from 'cart/components/checkout-button'
import { Modal, ModalBody } from 'components/modal'
import ConfirmUseRegistryDiscountForm from 'cart/components/confirm-use-registry-discount-form'
import { submitCheckout } from 'cart/api'
import SurchargeModal from 'components/surcharge-modal'
import { returnPolicyPath } from 'lib/urls'
import { CartSummary } from 'src/routes/(shop)/components/CartSummary'
import { InfoIconOutline } from '../../../components/icons'
import RegistryDiscountDisplay from './components/registry-discount-display'

class StoreOrderSummary extends Component {
  static propTypes = {
    babylistCredit: PropTypes.object, // Big.js object
    creditRemaining: PropTypes.object,
    estTax: PropTypes.object, // Big.js object
    href: PropTypes.string,
    registryDiscount: PropTypes.object, // Big.js object
    shippingCost: PropTypes.object.isRequired, // Big.js object
    showShippingInfo: PropTypes.bool,
    storeName: PropTypes.string.isRequired,
    subTotal: PropTypes.object.isRequired, // Big.js object
    cartItems: PropTypes.array,
    useRegistryDiscount: PropTypes.bool,
    reservation: PropTypes.object,
    registry: PropTypes.object,
    productSurcharge: PropTypes.number,
    inSaleUIFeature: PropTypes.bool,
  }

  static defaultProps = {
    babylistCredit: Big(0),
    creditRemaining: Big(0),
    estTax: Big(0),
    registryDiscount: Big(0),
    shippingCost: Big(0),
    showShippingInfo: true,
    subTotal: Big(0),
    inSaleUIFeature: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      showConfirmUseDiscountModal: false,
      showEstTotalHint: false,
      showSurchargeModal: false,
      submitting: false,
    }
  }

  renderBLOrderSummary() {
    const {
      creditRemaining,
      babylistCredit,
      isEligibleForRegistryDiscount,
      subTotal,
      estTax,
      productSurcharge,
      registryDiscount,
      shippingCost,
      showShippingInfo,
    } = this.props
    const { showSurchargeModal } = this.state

    const total = subTotal
      .plus(estTax)
      .minus(registryDiscount || 0)
      .plus(shippingCost)
      .minus(babylistCredit || 0)

    return (
      <div>
        <p className="h6">
          Subtotal{' '}
          <span className="pull-right">{numberToCurrency(subTotal)}</span>
        </p>
        <p className="h6">
          Estimated Tax{' '}
          <span className="pull-right">{numberToCurrency(estTax)}</span>
        </p>
        {showShippingInfo && (
          <>
            <p className="h6">
              Estimated Shipping*
              {productSurcharge && (
                <span
                  className="pull-right"
                  role="none"
                  onClick={() => this.setState({ showSurchargeModal: true })}
                >
                  <InfoIconOutline
                    style={{ height: '13px', margin: '0 0 6px 2px' }}
                  />
                </span>
              )}
              <span className="pull-right">
                {shippingCost.gt(0) ? (
                  numberToCurrency(shippingCost)
                ) : (
                  <b className="text-uppercase">Free</b>
                )}
              </span>
            </p>
            <SurchargeModal
              show={showSurchargeModal}
              onHide={() => this.setState({ showSurchargeModal: false })}
            />
          </>
        )}
        {(babylistCredit > 0 || isEligibleForRegistryDiscount) && (
          <p className="h6">Discounts</p>
        )}
        {babylistCredit > 0 ? (
          <p className="h6">
            - Babylist Shop Credit{' '}
            <span className="pull-right text-brand-info">
              -{numberToCurrency(babylistCredit)}
            </span>
          </p>
        ) : null}
        {creditRemaining > 0 && (
          <p className="h6">
            Credit Remaining{' '}
            <span className="pull-right text-brand-info">
              ({numberToCurrency(creditRemaining)})
            </span>
          </p>
        )}
        {isEligibleForRegistryDiscount && (
          <RegistryDiscountDisplay amount={registryDiscount} />
        )}
        <FreeShippingMessage />
        <hr className="mbm" />
        <BLTotal amount={total} />
      </div>
    )
  }

  handleSubmit = () => {
    const { cartItems } = this.props
    this.setState({ submitting: true })
    submitCheckout(cartItems)
      .then((resp) => {
        window.location.href = resp.checkoutPageUrl
      })
      .catch((resp) => {
        PubSub.publish(BLConstants.FLASH_MESSAGE, {
          variant: 'danger',
          message: resp?.error?.message || BLConstants.SYSTEM_ERROR_MESSAGE,
        })
      })
      .then(() => this.setState({ submitting: false }))
  }

  toggleShowConfirmUseDiscountModal = () => {
    this.setState({
      showConfirmUseDiscountModal: !this.state.showConfirmUseDiscountModal,
      submitting: !this.state.submitting,
    })
  }

  handleCheckout = () => {
    const { useRegistryDiscount } = this.props
    if (useRegistryDiscount) {
      this.toggleShowConfirmUseDiscountModal()
    } else {
      this.handleSubmit()
    }
  }

  renderCartSummary = () => <CartSummary />

  render() {
    const {
      cartItems,
      registry,
      reservations = [],
      subTotal,
      totalQuantity,
      useRegistryDiscount,
      inSaleUIFeature = false,
    } = this.props
    const { showConfirmUseDiscountModal, submitting } = this.state
    const checkoutCta = `Checkout ${totalQuantity} ${pluralize(
      'Item',
      totalQuantity
    )}`

    return (
      <div className="mvm pll-md">
        {inSaleUIFeature && reservations.length === 0
          ? this.renderCartSummary()
          : this.renderBLOrderSummary()}
        <CheckoutButton
          cartItems={cartItems}
          registry={reservations && reservations.length > 0 ? registry : null}
          reservations={reservations}
          storeName="Babylist"
          subTotal={subTotal}
          submitting={submitting}
          text={checkoutCta}
          useRegistryDiscount={useRegistryDiscount}
          onCheckoutClick={this.handleCheckout}
        />
        <ShippingDisclaimer className="mtm" />
        <Modal
          show={showConfirmUseDiscountModal}
          onHide={this.toggleShowConfirmUseDiscountModal}
        >
          <ModalBody>
            <ConfirmUseRegistryDiscountForm
              onConfirm={this.handleSubmit}
              onHide={this.toggleShowConfirmUseDiscountModal}
            />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

const FreeShippingMessage = () => (
  <div className="free-shipping-message phm mbl small">
    <img
      alt=""
      className="mhm"
      loading="lazy"
      nopin="nopin"
      role="presentation"
      src="https://images.babylist.com/image/upload/v1576013047/bl-heart_zyj97j.svg"
      width="30rem"
    />
    <div className="text-bold free-shipping-message-text">
      <div>
        {`Free shipping on orders $${BLConstants.FREE_SHIPPING_THRESHOLD}+`}
      </div>
      <div className="free-shipping-message-subtext">
        &{' '}
        <a className="free-shipping-message-link" href={returnPolicyPath}>
          free returns
        </a>{' '}
        from the Babylist Shop!
      </div>
    </div>
  </div>
)

const RegistryDiscount = ({ amount, className }) => (
  <p className={classNames('h6', className)}>
    <strong>
      {BLConstants.CONFIG.store.registryDiscountPctDisplay} Discount{' '}
      <span className="pull-right">-{numberToCurrency(amount)}</span>
    </strong>
  </p>
)

RegistryDiscount.propTypes = {
  amount: PropTypes.object.isRequired, // Big.js object
  className: PropTypes.string,
}

const BLTotal = ({ amount }) => (
  <p className="h6">
    <b>Total</b>{' '}
    <span className="pull-right">
      <b>{numberToCurrency(amount)}</b>
    </span>
  </p>
)

BLTotal.propTypes = {
  amount: PropTypes.object.isRequired, // Big.js object
}

export { StoreOrderSummary as default, RegistryDiscount, BLTotal }
