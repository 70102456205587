import PropTypes from 'prop-types'

export const PostShape = {
  author: PropTypes.shape({
    name: PropTypes.string,
    photo: PropTypes.shape({
      description: PropTypes.string,
      url: PropTypes.string,
    }),
    bio: PropTypes.string,
  }),
  createdAt: PropTypes.string,
  featuredImage: PropTypes.shape({
    attribution: PropTypes.string,
    attributionUrl: PropTypes.string,
    description: PropTypes.string,
    googleUrl: PropTypes.string,
    thumbnailUrl: PropTypes.string,
  }),
  id: PropTypes.string,
  locale: PropTypes.string,
  locales: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  meta: PropTypes.shape({
    description: PropTypes.string,
    pinterestImageUrl: PropTypes.string,
    seoDescription: PropTypes.string,
    seoTitle: PropTypes.string,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
  }),
  nextPostId: PropTypes.string,
  previousPostId: PropTypes.string,
  publishedAt: PropTypes.string,
  relatedPostIds: PropTypes.arrayOf(PropTypes.string),
  revision: PropTypes.number,
  slug: PropTypes.string,
  socialShareUrl: PropTypes.string,
  teaser: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedAtIso: PropTypes.string,
  videoId: PropTypes.string,
}

export const TopicShape = {
  description: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  pageTitle: PropTypes.string,
  path: PropTypes.string,
  slug: PropTypes.string,
}
