import SearchBarInput from './SearchBarInput/SearchBarInput'
import css from './SearchBar.scss'
import { Modal } from 'components/modal'
import { useEffect, useRef, useState } from 'react'
import SearchResults from './SearchResults/SearchResults'
import useSearch from './api/useSearch'
import SearchBarToggleButton from './SearchBarInput/SearchBarToggleButton'
import { unifiedSearchPopularSearches } from './constants'
import {
  getSearchResultsUrl,
  getSearchTerm,
  pushToSearchHistory,
} from './helpers'

interface SearchBarProps {
  className?: string
  eventLocation?: string
  isLoggedIn: boolean
}

const MobileSearchBar = ({
  className,
  eventLocation = '',
  isLoggedIn,
}: SearchBarProps) => {
  const [showModal, setShowModal] = useState(false)
  const [showResults, setShowResults] = useState(false)

  const { searchQuery, searchResults, updateSearchQuery, clearSearchQuery } =
    useSearch(getSearchTerm(), eventLocation, showResults)

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (showModal) {
      inputRef?.current?.focus()
    }
  }, [showModal])

  const handleCloseModal = () => {
    setShowModal(false)
    clearSearchQuery()
  }

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      pushToSearchHistory(searchResults, searchQuery)

      window.location.href = getSearchResultsUrl(
        eventLocation,
        searchQuery,
        searchResults
      )
    }
  }

  return (
    <div className={className}>
      <SearchBarToggleButton
        className={css.MobileSearchBar__Clickable}
        modalOpen={showModal}
        onClick={toggleModal}
        searchTerm={searchQuery}
        isLoggedIn={isLoggedIn}
      />
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className={css.MobileSearchBar__SearchModal}
      >
        <div className={css.MobileSearchBar__SearchModalBody}>
          <div className={css.MobileSearchBar__Input} onKeyDown={handleKeyDown}>
            <SearchBarInput
              inputRef={inputRef}
              query={searchQuery}
              setQuery={updateSearchQuery}
              clearQuery={clearSearchQuery}
              onFocus={() => setShowResults(true)}
              isLoggedIn={isLoggedIn}
            />
            <button
              className={css.MobileSearchBar__CancelButton}
              onClick={handleCloseModal}
            >
              Cancel
            </button>
          </div>

          {showResults && (
            <SearchResults
              searchResults={
                searchQuery.length == 0 || searchResults.length == 0
                  ? unifiedSearchPopularSearches
                  : searchResults
              }
              setQuery={updateSearchQuery}
              searchQuery={searchQuery}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}

export default MobileSearchBar
