import { useState } from 'react'
import classnames from 'classnames'
import { useQuery } from '@tanstack/react-query'
import { omit } from 'lodash'
import Link from 'components/link'
import Image from 'components/image'
import Quantity from 'components/quantity'
// @ts-ignore
import AddToCartButton from 'store/components/add-to-cart-button'
// @ts-ignore
import AddToRegistryButton from 'store/components/add-to-registry-button'
// @ts-ignore
import { addToBabylist, getProduct } from 'store/api'
import { successMessage } from 'lib/flash-message'
import { pdpPath } from 'lib/urls'
import { parseErrorMessage } from 'lib/api-utils'
import {
  SOURCE_QUICK_VIEW,
  ELECTRONIC_GIFT_CARD_MIN,
  ELECTRONIC_GIFT_CARD_MAX,
} from 'shared/constants'
import PriceTag, {
  PriceTagProps,
} from 'baby-design/components/PriceTag/PriceTag'
import { ProductCallouts } from 'components/ProductCallouts/ProductCallouts'
import { StarRating } from 'baby-design'
import { CurrentUser } from '../../../global'
import css from './QuickViewModal.styles.scss'
import SelectionControls from '../product-details-page/selection-controls'
import LoadingDots from '../../../baby-design/components/LoadingDots/LoadingDots'
import Modal from '../../../baby-design/components/Modal/Modal'
import { track } from '../../../lib/analytics'

interface QuickViewModalProps {
  productId: number
  onHide(): void
  show: boolean
  onAddToCartSuccess?: (
    productId: number,
    quantity: number,
    price?: number,
    type?: string,
    saleType?: string
  ) => void
  onAddToRegistrySuccess?: (
    regItem: any,
    updatedCurrentUser?: CurrentUser
  ) => void
  onProductLinkClick?: () => void
  showAddToRegistryButton?: boolean
}

const QuickViewModal = ({
  productId,
  onHide,
  show,
  onAddToCartSuccess = () => {},
  onAddToRegistrySuccess = () => {},
  onProductLinkClick = () => {},
  showAddToRegistryButton = false,
}: QuickViewModalProps) => {
  const { data, isLoading } = useQuery({
    queryKey: [productId],
    queryFn: () => getProduct(productId),
    refetchOnWindowFocus: false,
  })

  const handleOnAddToCartSuccess = (
    productId: number,
    quantity: number,
    price?: number,
    type?: string,
    saleType?: string
  ) => {
    onAddToCartSuccess(productId, quantity, price, type, saleType)
    successMessage(`${data?.genericProduct?.shortName} added to cart!`)
    onHide()
  }

  return (
    <Modal
      showCloseButton
      className={css.QuickViewModal}
      handleClose={onHide}
      isOpen={show}
    >
      {!isLoading && data?.genericProduct && (
        <QuickViewModalContent
          genericProduct={data.genericProduct}
          showAddToRegistryButton={showAddToRegistryButton}
          onAddToCartSuccess={handleOnAddToCartSuccess}
          onAddToRegistrySuccess={onAddToRegistrySuccess}
          onProductLinkClick={onProductLinkClick}
        />
      )}
      {isLoading && (
        <div className={css.QuickViewModal__loader}>
          <LoadingDots />
        </div>
      )}
    </Modal>
  )
}

interface Price {
  msrp?: number
  min?: number
  max?: number
  current?: number
}

const getPriceTagProps = (
  price?: Price,
  isElectronicGiftCard?: boolean
): PriceTagProps | null => {
  if (!price) return null

  if (isElectronicGiftCard) {
    return {
      minPrice: ELECTRONIC_GIFT_CARD_MIN,
      maxPrice: ELECTRONIC_GIFT_CARD_MAX,
      truncateZeroCents: true,
    }
  }

  return {
    msrp: price?.msrp,
    minPrice: price?.min,
    maxPrice: price?.max,
    currentPrice: price?.current,
  }
}

export const QuickViewModalContent = ({
  genericProduct,
  onAddToCartSuccess,
  onAddToRegistrySuccess,
  onProductLinkClick,
  showAddToRegistryButton,
}: any) => {
  const {
    activeProductId,
    attributes,
    brand,
    id,
    rating,
    reviewCount,
    shortName,
    slug,
    products,
    productAttributeMap,
  } = genericProduct
  const [activeProduct, setActiveProduct] = useState(
    products[activeProductId] || null
  )
  const [quantity, setQuantity] = useState(1)
  const {
    callouts = [],
    merchandisingMessage = '',
    showPurchaseQuantityLimit: showQuantityLimit = false,
    purchaseQuantityLimit: quantityLimit = 10,
    isElectronicGiftCard = false,
    availableOnBabylist,
    isCashFund,
    isFavor,
    isGiftCard,
    isCrowdfund,
    price,
    priceDetails,
  } = activeProduct || {}

  const productLinkUrl = activeProduct
    ? pdpPath(slug, id, activeProduct.id)
    : ''

  const disableAddToCart = !availableOnBabylist || isCashFund || isFavor
  const showQuantitySelector = !isGiftCard && !isCashFund && !isCrowdfund
  const supportsRatings =
    !isCashFund &&
    !isFavor &&
    !isElectronicGiftCard &&
    !isCrowdfund &&
    !!reviewCount

  const productType = isElectronicGiftCard
    ? track.ProductType.GIFT_CARD
    : track.ProductType.PRODUCT

  const productSaleType = priceDetails?.saleAttributes?.saleType
  const currentPrice = Number.isNaN(price?.current) ? undefined : price?.current

  const priceTagProps = getPriceTagProps(
    activeProduct.price,
    isElectronicGiftCard
  )

  const productPath = (productId: number) => pdpPath(slug, id, productId)

  const pdpLinkDecorator = (child: any) => (
    <Link
      newWindow
      className={css['QuickViewModal__pdp-link']}
      url={productLinkUrl}
      onClick={onProductLinkClick}
    >
      {child}
    </Link>
  )

  const addToRegistryAction = (registryId: number): Promise<any> =>
    addToBabylist({
      quantity,
      product_id: activeProduct.id,
      registryId,
      source: SOURCE_QUICK_VIEW,
    }).catch((resp: any) => {
      PubSub.publish(BLConstants.FLASH_MESSAGE, {
        message: parseErrorMessage(resp),
        variant: 'danger',
      })
    })

  return (
    <div className={css.QuickViewModal__layout}>
      <div>
        {pdpLinkDecorator(
          <Image src={activeProduct?.images[0]?.url} width="100%" />
        )}
      </div>
      <div>
        <div className={classnames(css.QuickViewModal__contentWrapper)}>
          <h3 className="flex col unset-all">
            {brand && (
              <div
                className={classnames(css.QuickViewModal__brand)}
                data-testid="brand"
              >
                {brand}
              </div>
            )}
            <div
              className={classnames(css.QuickViewModal__title)}
              data-testid="title"
            >
              {shortName}
            </div>
          </h3>
          {supportsRatings && (
            <StarRating rating={rating || 0} reviewCount={reviewCount || 0} />
          )}
          <PriceTag {...priceTagProps} />
          {merchandisingMessage && (
            <div
              className={classnames(css.QuickViewModal__merchandisingMessage)}
            >
              {merchandisingMessage}
            </div>
          )}
          {callouts && (
            <div className={classnames(css.QuickViewModal__callouts)}>
              <ProductCallouts callouts={callouts} textSize={13} />
            </div>
          )}
          {pdpLinkDecorator('See full details')}
        </div>
        <SelectionControls
          allAttributes={attributes}
          attributeLabelOverrides={
            activeProduct.isCashFund ? { amount: 'goal' } : null
          }
          /* eslint-disable-next-line react/jsx-no-bind */
          productAttributeMap={productAttributeMap}
          productPath={productPath}
          products={products}
          selectedAttributes={omit(activeProduct.attributes, 'sizeOnCatalog')}
          onProductChange={(productId: any) =>
            setActiveProduct(products[productId])
          }
        />
        {showQuantitySelector && (
          <Quantity
            className="mtm mbl"
            limit={showQuantityLimit ? quantityLimit : null}
            max={quantityLimit}
            min={1}
            value={quantity}
            onChange={(qty: number) => setQuantity(qty)}
          />
        )}
        {showAddToRegistryButton && (
          <AddToRegistryButton
            useInfoButtonStyle
            addToRegistry={addToRegistryAction}
            buttonStyle={{ pill: true, block: true }}
            productId={activeProduct.id}
            quantity={quantity}
            style={undefined}
            onSuccess={onAddToRegistrySuccess}
          />
        )}
        <AddToCartButton
          useInfoButtonStyle
          buttonStyle={{
            pill: true,
            block: true,
            empty: showAddToRegistryButton,
          }}
          disabled={disableAddToCart}
          productId={activeProduct.id}
          productPrice={currentPrice}
          quantity={quantity}
          style={undefined}
          onSuccess={onAddToCartSuccess}
          productSaleType={productSaleType}
          productType={productType}
        />
      </div>
    </div>
  )
}

export default QuickViewModal
