import classNames from 'classnames'

import Link from 'components/link'
import { helloBabyPostPath } from 'lib/urls'
import PostCard from '../PostCard'
import PostCardRow from '../post-card-row'

import css from './TopicSection.styles.scss'
import { StyledSubHeading } from '../../../components/headings'

interface TopicSectionProps {
  topic: Topic
  posts: Post[]
  className?: string
  showAllButton?: boolean
  showTopics?: boolean
  hideLastPostSm?: boolean
  teaserText?: string
}

const TopicSection = ({
  topic,
  posts,
  showAllButton = true,
  showTopics,
  hideLastPostSm = false,
  teaserText,
}: TopicSectionProps) => {
  return (
    <div
      key={'topic-container-' + topic}
      className={classNames(css.topicContainer, 'clearfix pbxl mbxl')}
    >
      <StyledSubHeading className={classNames(css.topicHeader, 'h3 mbm mtn')}>
        {topic.name}
      </StyledSubHeading>
      {teaserText && <div className="mbl">{teaserText}</div>}
      <PostCard
        cardSize={'medium'}
        title={posts[0].title}
        image={posts[0].featuredImage?.url}
        teaserText={posts[0].teaser}
        slug={posts[0].slug}
      />
      <div className="clearfix">
        <PostCardRow
          showTopics={showTopics}
          hideLastPostSm={hideLastPostSm}
          posts={posts.slice(1, posts.length)}
          columns={3}
          className="mtxl"
        />
      </div>
      {showAllButton ? (
        <div className="text-center">
          <Link
            className={classNames(css.viewAllButton, 'btn-empty btn btn-info')}
            to={helloBabyPostPath(topic.slug)}
          >
            {topic.slug === 'sample-registries'
              ? 'View all sample registries'
              : 'View all in ' + topic.name}
          </Link>
        </div>
      ) : null}
    </div>
  )
}

export default TopicSection
