import React, { useRef } from 'react'
import css from './Meter.styles.scss'
import { MeterProps } from './Meter.types'
import classNames from 'classnames'
import {
  getPointerBoundaries,
  useCalculateSharedValues,
  useSetPointerAnimation,
  useSetProgressBarAnimation,
  useSetProgressBarFill,
} from './Meter.utils'

const Meter: React.FC<MeterProps> = ({
  animationDuration = 0.5,
  animationFunction = 'ease-in-out',
  totalSegments = 1,
  percentage,
  labels = [],
  size = 'md',
  fillClass,
  PointerComponent,
  label,
  rightLabel,
  starterFill = false,
  pointerClass,
  pointerLeftClass,
  pointerRightClass,
}) => {
  const previousPercentageRef = useRef(0)
  const fillRefs = useRef<(HTMLDivElement | null)[]>([])
  const pointerRef = useRef<HTMLDivElement | null>(null)

  const sizeClass = `Meter__segment--${size}`
  const showLabel = (label || rightLabel) && !PointerComponent
  const pointerBoundaries = getPointerBoundaries('lg')

  const {
    filledSegments,
    lastSegmentPercentage,
    clampedPercentage,
    filledSegmentDelta,
    previousFilledSegments,
    clampedSegments,
  } = useCalculateSharedValues({
    totalSegments,
    percentage,
    previousPercentage: previousPercentageRef.current,
  })

  useSetProgressBarFill({
    fillRefs,
    previousPercentageRef,
    filledSegments,
    lastSegmentPercentage,
    percentage: clampedPercentage,
  })

  useSetProgressBarAnimation({
    filledSegmentDelta,
    previousFilledSegments,
    fillRefs,
    animationDuration,
    animationFunction,
  })

  useSetPointerAnimation({
    percentage: clampedPercentage,
    filledSegmentDelta,
    animationDuration,
    animationFunction,
    pointerRef,
  })

  return (
    <div className={css.MeterContainer}>
      {showLabel && (
        <div className={css.Meter__labelContainer}>
          <span className={css.Meter__labelText}>{label}</span>
          {rightLabel && (
            <span className={css.Meter__rightLabel}>{rightLabel}</span>
          )}
        </div>
      )}
      {PointerComponent && (
        <div
          className={classNames(
            css.Meter__pointer,
            {
              [css.left]: clampedPercentage <= pointerBoundaries.min,
              [css.right]: clampedPercentage >= pointerBoundaries.max,
            },
            pointerLeftClass &&
              clampedPercentage <= pointerBoundaries.min &&
              pointerLeftClass,
            pointerRightClass &&
              clampedPercentage >= pointerBoundaries.max &&
              pointerRightClass,
            pointerClass // Apply the optional pointerClass
          )}
          ref={pointerRef}
        >
          <div className={css.Meter__pointerContent}>{PointerComponent}</div>
        </div>
      )}
      <div className={css.Meter}>
        {Array.from({ length: clampedSegments }, (_, index) => {
          return (
            <div key={index} className={css.Meter__segmentContainer}>
              <div className={classNames(css.Meter__segment, css[sizeClass])}>
                <div className={css.Meter__segment_fill}>
                  <div
                    className={classNames(css.Meter__fill, fillClass, {
                      [css.Meter__starterFill]: starterFill,
                    })}
                    ref={(el) => (fillRefs.current[index] = el)}
                  />
                </div>
              </div>
              <div className={css.Meter__label}>{labels[index]}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Meter
