import { useEffect } from 'react'

export const sizeMinimumPercentageMap: { [key: string]: number } = {
  lg: 3,
  md: 2.25,
  sm: 1.25,
  xs: 1,
}

export const getPointerBoundaries = (size: string) => {
  return {
    min: sizeMinimumPercentageMap[size],
    max: 100 - sizeMinimumPercentageMap[size],
  }
}

interface CalculateSharedValuesInterface {
  percentage: number
  totalSegments: number
  previousPercentage: number
}

export const useCalculateSharedValues = ({
  totalSegments,
  percentage,
  previousPercentage,
}: CalculateSharedValuesInterface) => {
  // Clamp segment count
  const clampedSegments = Math.max(1, totalSegments)

  // Clamp percentage to be between minimumPercentage and 100
  const clampedPercentage = Math.max(0, Math.min(100, percentage))

  // Express percentage as a decimal
  const decimalPercentage = clampedPercentage / 100

  // Calculate the number of filled segments
  const filledSegments = Math.floor(decimalPercentage * clampedSegments)

  // Calculate the percentage of the last segment (not fully filled segment)
  const lastSegmentPercentage =
    (decimalPercentage * clampedSegments - filledSegments) * 100

  // Calculate the percentage of the previous percentage expressed as a decimal
  const previousDecimalPercentage = previousPercentage / 100

  // Calculate the number of filled segments from the previous percentage
  const previousFilledSegments = Math.floor(
    previousDecimalPercentage * clampedSegments
  )

  // Calculate the number of filled segments that have changed (direction of change represented by sign)
  const filledSegmentDelta = filledSegments - previousFilledSegments

  return {
    filledSegments,
    lastSegmentPercentage,
    clampedPercentage,
    filledSegmentDelta,
    previousFilledSegments,
    clampedSegments,
  }
}

interface SetProgressBarFillInterface {
  fillRefs: React.MutableRefObject<(HTMLDivElement | null)[]>
  previousPercentageRef: React.MutableRefObject<number>
  filledSegments: number
  lastSegmentPercentage: number
  percentage: number
}

export const useSetProgressBarFill = ({
  fillRefs,
  previousPercentageRef,
  filledSegments,
  lastSegmentPercentage,
  percentage,
}: SetProgressBarFillInterface) => {
  useEffect(() => {
    fillRefs.current.forEach((fillRef, index) => {
      if (fillRef) {
        // calculate segment percentage
        let segmentPercentage = 0
        if (index < filledSegments) segmentPercentage = 100
        if (index === filledSegments) segmentPercentage = lastSegmentPercentage
        if (index > filledSegments) segmentPercentage = 0

        // set segment width
        fillRef.style.width = `${segmentPercentage}%`
      }
    })

    previousPercentageRef.current = percentage
  }, [
    fillRefs,
    previousPercentageRef,
    filledSegments,
    lastSegmentPercentage,
    percentage,
  ])
}

interface SetProgressBarAnimationInterface {
  filledSegmentDelta: number
  previousFilledSegments: number
  fillRefs: React.MutableRefObject<(HTMLDivElement | null)[]>
  animationDuration: number
  animationFunction: string
}

export const useSetProgressBarAnimation = ({
  filledSegmentDelta,
  previousFilledSegments,
  fillRefs,
  animationDuration,
  animationFunction,
}: SetProgressBarAnimationInterface) => {
  useEffect(() => {
    fillRefs.current.forEach((fillRef, index) => {
      if (fillRef) {
        // Set base animation attribute
        fillRef.style.transition = `width ${animationDuration}s ${animationFunction}`

        // Calculate transition delay based on how many segments are changing and in which direction
        let transitionDelay = 0
        if (filledSegmentDelta > 0) {
          transitionDelay = (index - previousFilledSegments) * animationDuration
        } else if (filledSegmentDelta < 0) {
          transitionDelay = -(filledSegmentDelta + index) * animationDuration
        }

        // Set transition delay
        fillRef.style.transitionDelay = `${transitionDelay}s`
      }
    })
  }, [filledSegmentDelta, previousFilledSegments, fillRefs, animationDuration])
}

interface SetPointerAnimationInterface {
  percentage: number
  filledSegmentDelta: number
  animationDuration: number
  animationFunction: string
  pointerRef: React.MutableRefObject<HTMLDivElement | null>
}

export const useSetPointerAnimation = ({
  percentage,
  filledSegmentDelta,
  animationDuration,
  animationFunction,
  pointerRef,
}: SetPointerAnimationInterface) => {
  useEffect(() => {
    if (!pointerRef.current) return

    // Calculate pointer left position based on percentage
    let pointerLeftPositionStyle = `${percentage}%`
    const pointerBoundaries = getPointerBoundaries('lg')

    // If we are above or below the pointer boundaries, adjust the pointer position
    // to accommodate the change in style of the pointer (left vs right aligned pointer)
    const { width } = pointerRef.current.getBoundingClientRect()
    if (percentage >= pointerBoundaries.max) {
      pointerLeftPositionStyle = `calc(${percentage}% - ${width / 2}px)`
    }
    if (percentage <= pointerBoundaries.min) {
      pointerLeftPositionStyle = `calc(${percentage}% + ${width / 2}px)`
    }

    // Set pointer animation properties
    pointerRef.current?.style.setProperty(
      'transition',
      `left ${
        animationDuration * Math.abs(filledSegmentDelta)
      }s ${animationFunction}`
    )

    // Set pointer left position
    pointerRef.current?.style.setProperty('left', pointerLeftPositionStyle)
  }, [percentage, filledSegmentDelta, animationDuration, pointerRef])
}
