import React from 'react'
import classNames from 'classnames'

import css from './IconButton.styles.scss'

import { IconButtonProps } from './IconButton.types'

const IconButton: React.FC<IconButtonProps> = ({
  variant,
  size,
  className,
  children,
  onClick = () => {},
  ...props
}) => {
  const iconButtonClasses = classNames(
    css.icon_button,
    css[`icon_button--style-${variant}`],
    css[`icon_button--size-${size}`],
    className
  )

  return (
    <button
      className={iconButtonClasses}
      onClick={(e) => {
        e.currentTarget.blur()
        onClick(e)
      }}
      {...props}
    >
      {children}
    </button>
  )
}

export default IconButton
