/* This is sad, but doing this until we have a declaration file */
// @ts-nocheck
import { useState } from 'react'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import classNames from 'classnames'
import { Modal } from 'components/modal'
import { getBooleanCookie, setCookies } from 'lib/cookies'
import { ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import { MultiStepWizard } from 'components/presentation'
import { ChevronLeftIcon } from 'components/icons'
import { PrimaryButton, InfoButton } from 'components/buttons'
import { GiftGiverInfoForm } from 'cart/components/gift-giver-info-form'
import { useTracking, track } from 'lib/analytics'
import { NEW_RESERVATION_ID } from 'cart/constants'
import PurchaseInfoForm from 'cart/components/purchase-info-form'
import RegItemImage from 'registry/components/reg-item-image'
import { getReservationsByCurrentVisitor } from 'registry/reducers'
import {
  getGiftMessage,
  getIsGiftGiverThankYouAddressProvided,
} from 'cart/reducers/reservations-reducer'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import {
  openGiftMessageModal,
  openGiftTrackerModal,
  openThankYouAddressModal,
} from 'registry/modals/actions'
import { updateGiftMessage, fetchCategorizedRegItems } from 'registry/actions'
import css from './reserve-purchased-gift-modal.scss'

const FIRST_STEP = 1
const SECOND_STEP = 2
const THIRD_STEP = 3
const HOW_TO_STEP = 4

interface ReservePurchasedGiftModalProps {
  giftMessage: {
    name: string
    note: string
  }
  regItem: {
    id: number
    registryId: number
    price: string
  }
  reservation: any
  onHide(): void
  onSubmit(reservation: any): any
  registry?: {
    firstNames?: string
  }
  fetchRegItems(): void
  openGiftMessage(giftMessage: any, onSubmit: any): any
  openGiftTracker(): void
  saveGiftMessage(giftMessage: any)
  openThankYouAddress(data: {
    giftGiverInfo: GiftGiverInfoValues
    afterSubmit: () => any
  }): void
  isGiftGiverThankYouAddressProvided: boolean
}

export interface GiftGiverInfoValues {
  email: string | null
  giftAmount?: string | null
  name: string | null
  productId: number | null
  quantity?: string | null
}

interface PurchaseInfoValues {
  orderNumber: string
  orderNumberNotAvailable: boolean
  storeName: string
}

export const ReservePurchasedGiftModal = ({
  fetchRegItems,
  giftMessage,
  regItem,
  reservation,
  onHide,
  onSubmit,
  registry,
  openGiftMessage,
  saveGiftMessage,
  openGiftTracker,
  openThankYouAddress,
  isGiftGiverThankYouAddressProvided,
}: ReservePurchasedGiftModalProps) => {
  const [currentUser] = useCurrentUser()
  const { isGroupGift, isFavorite, imgUrl, title } = regItem
  const [activeStep, setActiveStep] = useState(
    !reservation ? FIRST_STEP : THIRD_STEP
  )
  const [giftGiverInfo, setGiftGiverInfo] = useState<GiftGiverInfoValues>({
    email: Cookies.get('email'),
    giftAmount: null,
    name: Cookies.get('name'),
    productId: null,
    quantity: null,
    isSurprise: true,
  })

  const isRegistryOwnerOrPartner = currentUser?.registries.some(
    (r) => r.id === registry?.id && (r.role === 'owner' || r.role === 'partner')
  )

  const tracker = useTracking()

  const nextStep = ({
    email,
    giftAmount,
    name,
    productId,
    quantity,
    isSurprise,
  }: GiftGiverInfoValues) => {
    setGiftGiverInfo({
      email,
      giftAmount,
      name,
      productId,
      quantity,
      isSurprise,
    })
    setActiveStep(THIRD_STEP)
  }

  const handleUpdateGiftMessage = (data) => {
    saveGiftMessage(data).then(() => {
      if (!isGiftGiverThankYouAddressProvided && !isRegistryOwnerOrPartner) {
        openThankYouAddress({
          giftGiverInfo,
          afterSubmit: openGiftTracker,
        })
      } else {
        openGiftTracker()
      }
    })
  }

  const handleSubmit = ({
    orderNumber,
    orderNumberNotAvailable,
    storeName,
    quantity: quantityFromForm,
  }: PurchaseInfoValues) => {
    const {
      email,
      giftAmount,
      name,
      productId,
      quantity: quantityFromGiftGiverInfo,
      isSurprise,
    } = giftGiverInfo

    // When we are modifying the quantity of an existing reservation, the gift giver info
    // is not populated. In this case, we want to use the quantity from the form.
    const quantity = quantityFromGiftGiverInfo || quantityFromForm

    const payload = reservation
      ? {
          ...reservation,
          orderNumber,
          orderNumberNotAvailable,
          storeName,
        }
      : {
          id: NEW_RESERVATION_ID,
          regItemId: regItem.id,
          regItem,
          registryId: regItem.registryId,
          email,
          name,
          productId,
          quantity: quantity || 1,
          giftAmount,
          isPurchased: true,
          orderNum: orderNumber,
          orderNumberNotAvailable,
          subscribeToMarketingEmail: getBooleanCookie({
            name: 'subscribeToMarketingEmail',
            defaultValue: true,
          }),
          storeName,
        }

    if (isSurprise) {
      payload['preferences'] = { surprise: { isSurprise } }
    }

    // reservationUpdated event is fired only for existing reservations
    const trackReservationUpdated = () => {
      if (reservation && reservation.isPurchased) {
        tracker.trackEvent({
          regItem,
          regItemQuantity: Number(quantity) || 1,
          event: track.reservationUpdated,
          eventType: track.EventType.ITEM_QUANTITY_UPDATED,
        })
      }
    }

    // purchaseConfirmed event is fired only for new reservations
    const trackPurchaseConfirmed = () => {
      if (!reservation) {
        tracker.trackEvent({
          regItemIds: [regItem.id],
          regItemQuantity: Number(quantity) || 1,
          event: track.purchaseConfirmed,
          eventType: track.EventType.MARK_AS_PURCHASED,
        })
      }
    }

    return onSubmit(payload).then(() => {
      if (!reservation) {
        setCookies({ name, email })
      }
      trackReservationUpdated()
      trackPurchaseConfirmed()
      fetchRegItems()
      onHide()
      if (!giftMessage?.note?.length > 0) {
        openGiftMessage(giftMessage, handleUpdateGiftMessage)
      } else if (
        !isGiftGiverThankYouAddressProvided &&
        !isRegistryOwnerOrPartner
      ) {
        openThankYouAddress({
          afterSubmit: openGiftTracker,
          giftGiverInfo,
        })
      } else {
        openGiftTracker()
      }
    })
  }

  return (
    <Modal show size="large" onHide={onHide}>
      <MultiStepWizard activeStep={activeStep} animateInitialState={false}>
        {/* Step 1 */}
        <div>
          <ModalHeader
            closeButton
            onHide={onHide}
            className={classNames('with-title')}
          >
            <ModalTitle className="h3" style={{ textAlign: 'center' }}>
              Did you already purchase this?
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className="flex align-center well">
              <RegItemImage
                className="mrm"
                isFavorite={isFavorite}
                isGroupGift={isGroupGift}
                src={imgUrl}
                width={125}
              />
              <span>{title}</span>
            </div>
            <div className="flex">
              <InfoButton
                className="mrs"
                pill
                block
                empty
                onClick={() => setActiveStep(HOW_TO_STEP)}
              >
                Not Yet
              </InfoButton>
              <InfoButton pill block onClick={() => setActiveStep(SECOND_STEP)}>
                Yes
              </InfoButton>
            </div>
          </ModalBody>
        </div>
        {/* Step 2 */}
        <div>
          <ModalHeader
            closeButton
            onHide={onHide}
            className={classNames('flex space-between row with-title')}
          >
            <PrimaryButton
              className="btn-unstyled plm"
              onClick={() => setActiveStep(FIRST_STEP)}
            >
              <ChevronLeftIcon height="16" />
            </PrimaryButton>
            <ModalTitle className="h3 prl" style={{ textAlign: 'center' }}>
              Sweet! 🎉 Who is this from?
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div>
              <GiftGiverInfoForm
                onFormSubmit={nextStep}
                regItem={regItem}
                ctaText="Next"
                registrantNames={registry?.firstNames}
              />
            </div>
          </ModalBody>
        </div>
        {/* Step 3 */}
        <div>
          <ModalHeader
            closeButton
            onHide={onHide}
            className={classNames('flex space-between row')}
          >
            {!reservation && (
              <PrimaryButton
                className="btn-unstyled phm"
                onClick={() => setActiveStep(SECOND_STEP)}
              >
                <ChevronLeftIcon height="16" />
              </PrimaryButton>
            )}
            <ModalTitle className="h3 prl" style={{ textAlign: 'center' }}>
              Help{' '}
              {registry && registry.firstNames
                ? registry.firstNames
                : 'the family'}{' '}
              in case of a return 📦
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <PurchaseInfoForm
              autofillStore={!!reservation}
              registry={registry}
              reservation={reservation}
              onSubmit={handleSubmit}
              submitBtnText="Save"
              storeName={reservation && reservation.storeName}
              hideStoreInput={false}
            />
          </ModalBody>
        </div>
        {/* Step 4 */}
        <div>
          <ModalHeader
            closeButton
            onHide={onHide}
            className={classNames('with-title')}
          >
            <ModalTitle className="h3" style={{ textAlign: 'center' }}>
              How "I've Purchased This" works:
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <ol className="pbl pll lh-lg">
              <li>Purchase item from any store 🛍️</li>
              <li>
                Return to Babylist with your order number and select "I've
                Purchased This"
              </li>
            </ol>
            <div className="text-center mbm">
              <InfoButton style={{ minWidth: 200 }} pill onClick={onHide}>
                Back to Registry
              </InfoButton>
            </div>
          </ModalBody>
        </div>
      </MultiStepWizard>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const reservations = Object.values(getReservationsByCurrentVisitor(state))
  return {
    giftMessage: getGiftMessage(reservations),
    isGiftGiverThankYouAddressProvided:
      getIsGiftGiverThankYouAddressProvided(reservations),
  }
}

const mapDispatchToProps = (dispatch) => ({
  openGiftTracker: () => dispatch(openGiftTrackerModal()),
  openGiftMessage: (data, onSubmit) =>
    dispatch(openGiftMessageModal(data, onSubmit)),
  saveGiftMessage: (data) => dispatch(updateGiftMessage(data)),
  fetchRegItems: () => dispatch(fetchCategorizedRegItems()),
  openThankYouAddress: (data) => dispatch(openThankYouAddressModal(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservePurchasedGiftModal)
