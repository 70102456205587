import React from 'react'
import classNames from 'classnames'
import css from 'shared/Footer/SocialLinks/SocialLinks.scss'
import LinkedIn from '../../../about/components/Layout/icons/LinkedIn.svg'

interface IconProps {
  className?: string
}

export const TikTokIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={classNames(css.socialLink__Icon, className)}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M11.5165 2C11.5165 1.58579 11.8523 1.25 12.2665 1.25H15.7044C15.9042 1.25 16.0957 1.32971 16.2365 1.47144C16.3773 1.61318 16.4557 1.80523 16.4544 2.005C16.4527 2.24873 16.4735 2.49209 16.5163 2.73202C16.6173 3.26869 16.8265 3.7792 17.1311 4.23245C17.4362 4.68653 17.8308 5.0736 18.2906 5.37002L18.2976 5.37453C18.9514 5.8063 19.7177 6.03624 20.5011 6.03563C20.7002 6.03547 20.8911 6.11442 21.0318 6.25509C21.1726 6.39576 21.2517 6.58661 21.2517 6.78563V10.2C21.2517 10.6136 20.9169 10.9491 20.5033 10.95C19.0908 10.953 17.7044 10.6221 16.4544 9.99245V15.6498C16.4539 17.0962 16.0119 18.5082 15.1873 19.6966C14.3627 20.8849 13.195 21.7932 11.8403 22.2999C10.4855 22.8066 9.00834 22.8876 7.60631 22.5321C6.20429 22.1765 4.94424 21.4013 3.99471 20.3103C3.04517 19.2192 2.4514 17.8642 2.29282 16.4265C2.13424 14.9888 2.41841 13.5369 3.10731 12.2651C3.79622 10.9933 4.85702 9.96217 6.14785 9.30958C7.43867 8.65699 8.89798 8.41408 10.3306 8.61334C10.7013 8.66491 10.9773 8.9819 10.9773 9.35619V12.8683C10.9773 13.1071 10.8635 13.3317 10.6709 13.473C10.4783 13.6143 10.2299 13.6554 10.0021 13.5836C9.56696 13.4467 9.0997 13.4507 8.667 13.5951C8.23429 13.7395 7.85828 14.0169 7.59265 14.3878C7.32702 14.7586 7.18536 15.2039 7.18788 15.6601C7.1904 16.1162 7.33698 16.5599 7.6067 16.9278C7.87641 17.2957 8.25546 17.569 8.68973 17.7086C9.12401 17.8482 9.59129 17.8471 10.0249 17.7053C10.4584 17.5635 10.8361 17.2884 11.104 16.9192C11.3719 16.5499 11.5163 16.1055 11.5165 15.6494V2Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)

export const InstagramIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={classNames(css.socialLink__Icon, className)}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75Z"
      fill="white"
    />
    <path
      clipRule="evenodd"
      d="M9.96644 1.25H14.0336C15.4053 1.25 16.4807 1.24999 17.3451 1.32061C18.2252 1.39252 18.9523 1.54138 19.6104 1.87671C20.6924 2.42798 21.572 3.30762 22.1233 4.38956C22.4586 5.04768 22.6075 5.77479 22.6794 6.65494C22.75 7.51926 22.75 8.59471 22.75 9.96637V14.0336C22.75 15.4053 22.75 16.4807 22.6794 17.3451C22.6075 18.2252 22.4586 18.9523 22.1233 19.6104C21.572 20.6924 20.6924 21.572 19.6104 22.1233C18.9523 22.4586 18.2252 22.6075 17.3451 22.6794C16.4807 22.75 15.4053 22.75 14.0336 22.75H9.96637C8.59471 22.75 7.51926 22.75 6.65494 22.6794C5.77479 22.6075 5.04768 22.4586 4.38956 22.1233C3.30762 21.572 2.42798 20.6924 1.87671 19.6104C1.54138 18.9523 1.39252 18.2252 1.32061 17.3451C1.24999 16.4807 1.25 15.4053 1.25 14.0336V9.96644C1.25 8.59472 1.24999 7.51929 1.32061 6.65494C1.39252 5.77479 1.54138 5.04768 1.87671 4.38955C2.42798 3.30762 3.30762 2.42798 4.38955 1.87671C5.04769 1.54138 5.77479 1.39252 6.65494 1.32061C7.51929 1.24999 8.59472 1.25 9.96644 1.25ZM16.25 6C16.25 5.30964 16.8096 4.75 17.5 4.75C18.1904 4.75 18.75 5.30964 18.75 6C18.75 6.69036 18.1904 7.25 17.5 7.25C16.8096 7.25 16.25 6.69036 16.25 6ZM7.25 12C7.25 9.37665 9.37665 7.25 12 7.25C14.6234 7.25 16.75 9.37665 16.75 12C16.75 14.6234 14.6234 16.75 12 16.75C9.37665 16.75 7.25 14.6234 7.25 12Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)

export const FacebookIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={classNames(css.socialLink__Icon, className)}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 1.25C3.82436 1.25 1.25 3.82436 1.25 7V17C1.25 20.1756 3.82436 22.75 7 22.75H10.1528C11.1216 22.75 11.9061 21.9628 11.9028 20.9939L11.8809 14.6945H9.2168V12.6389H11.8835V10.9467C11.8835 9.8042 12.3373 8.7085 13.1452 7.90064C13.953 7.09277 15.0487 6.63892 16.1912 6.63892H17.4946V8.69447H16.1912C15.8955 8.69447 15.6026 8.75273 15.3294 8.86591C15.0561 8.9791 14.8078 9.14499 14.5987 9.35413C14.3895 9.56327 14.2236 9.81155 14.1105 10.0848C13.9973 10.3581 13.939 10.6509 13.939 10.9467V12.6389H16.7383C16.8857 12.6389 17.0011 12.7658 16.9872 12.9126L16.8394 14.4681C16.8272 14.5964 16.7194 14.6945 16.5905 14.6945H13.9356L13.9642 21.0079C13.9686 21.9713 14.7508 22.75 15.7142 22.75H17C20.1756 22.75 22.75 20.1756 22.75 17V7C22.75 3.82436 20.1756 1.25 17 1.25H7Z"
      fill="white"
    />
  </svg>
)

export const PinterestIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={classNames(css.socialLink__Icon, className)}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.8981 22.75 9.8349 22.5842 8.83402 22.2762L10.2917 16.7164C13.8451 18.2362 18.25 15.7304 18.25 11.5C18.25 8.27186 15.4271 5.69446 11.9999 5.69446C10.0375 5.69446 8.20047 6.54931 7.02501 7.93568C5.83515 9.33904 5.33652 11.2778 6.07 13.3392C6.20886 13.7295 6.63778 13.9333 7.02803 13.7944C7.41827 13.6555 7.62207 13.2266 7.48321 12.8364C6.94009 11.31 7.30316 9.92709 8.16913 8.90573C9.04951 7.86738 10.4624 7.19446 11.9999 7.19446C14.7084 7.19446 16.75 9.20593 16.75 11.5C16.75 14.6623 13.3356 16.5593 10.6763 15.2425L11.7261 11.188C11.8299 10.787 11.589 10.3778 11.188 10.2739C10.787 10.1701 10.3778 10.411 10.2739 10.812L9.08208 15.4155L9.0815 15.4177L7.4262 21.7313C3.77635 20.0128 1.25 16.3014 1.25 12Z"
      fill="white"
    />
  </svg>
)

export const XIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={classNames(css.socialLink__Icon, className)}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0683 3.48941C20.3386 3.17553 20.3033 2.70197 19.9894 2.43169C19.6755 2.1614 19.202 2.19674 18.9317 2.51062L12.9873 9.41377L8.63172 2.59634C8.49392 2.38066 8.25564 2.25013 7.9997 2.25013H4.01244C3.9151 2.24838 3.81669 2.26553 3.72293 2.30285C3.67357 2.32246 3.62679 2.34716 3.58323 2.3763C3.46231 2.45694 3.37217 2.56696 3.31626 2.6908C3.28866 2.75168 3.26896 2.81691 3.25846 2.88517C3.23743 3.0204 3.25314 3.16177 3.30913 3.29322C3.32636 3.33378 3.34706 3.37251 3.37087 3.40904L9.66911 13.2671L3.43167 20.5106C3.16139 20.8245 3.19673 21.2981 3.51061 21.5683C3.82449 21.8386 4.29804 21.8033 4.56833 21.4894L10.5122 14.5868L14.8636 21.3977C14.8695 21.4071 14.8756 21.4164 14.8819 21.4255C14.9523 21.5279 15.044 21.6075 15.1472 21.6623C15.2387 21.7111 15.3414 21.7415 15.4505 21.7485C15.4703 21.7499 15.4902 21.7504 15.5102 21.7501H19.4997C19.7737 21.7501 20.0259 21.6007 20.1575 21.3605C20.2891 21.1202 20.2792 20.8272 20.1317 20.5963L13.8304 10.7334L20.0683 3.48941Z"
      fill="white"
    />
  </svg>
)

export const YouTubeIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={classNames(css.socialLink__Icon, className)}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6.52147 2.54078C7.94527 2.31166 9.74786 2.25 12 2.25C14.2521 2.25 16.0547 2.31166 17.4785 2.54078C18.9034 2.77007 20.0377 3.17979 20.8795 3.94505C21.7307 4.71887 22.1881 5.76906 22.4396 7.07713C22.6888 8.3728 22.75 9.99883 22.75 12C22.75 14.0012 22.6888 15.6273 22.4396 16.9229C22.1881 18.231 21.7307 19.2812 20.8795 20.055C20.0377 20.8203 18.9034 21.23 17.4785 21.4593C16.0547 21.6884 14.2521 21.75 12 21.75C9.74786 21.75 7.94527 21.6884 6.52147 21.4593C5.09658 21.23 3.96228 20.8203 3.1205 20.055C2.26929 19.2812 1.81192 18.231 1.56037 16.9229C1.3112 15.6273 1.25 14.0012 1.25 12C1.25 9.99883 1.3112 8.3728 1.56037 7.07713C1.81192 5.76906 2.26929 4.71887 3.12049 3.94505C3.96228 3.17979 5.09658 2.77007 6.52147 2.54078ZM11.3297 9.50623L13.9957 11.1058C14.1754 11.2136 14.325 11.3034 14.4366 11.3851C14.5497 11.4678 14.6565 11.5648 14.7154 11.6995C14.7991 11.8911 14.7991 12.1088 14.7154 12.3004C14.6565 12.435 14.5497 12.5321 14.4366 12.6148C14.325 12.6965 14.1754 12.7863 13.9957 12.8941L11.3297 14.4937C11.1404 14.6073 10.9835 14.7014 10.8536 14.764C10.7227 14.8271 10.5814 14.8777 10.4304 14.8636C10.217 14.8437 10.0223 14.7335 9.89547 14.5607C9.80569 14.4385 9.77644 14.2913 9.76317 14.1466C9.74999 14.003 9.75 13.8201 9.75 13.5993V10.4006C9.75 10.1799 9.74999 9.99687 9.76317 9.85326C9.77644 9.7086 9.80569 9.56141 9.89547 9.43915C10.0223 9.2664 10.217 9.15617 10.4304 9.13628C10.5814 9.1222 10.7227 9.17284 10.8536 9.23589C10.9835 9.29848 11.1404 9.39263 11.3297 9.50623Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)

export const LinkedInIcon: React.FC<IconProps> = ({ className }) => (
  <LinkedIn className={classNames(css.socialLink__Icon, className)} />
)
