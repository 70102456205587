/* eslint-disable */
import { Component } from 'react'
import PropTypes from 'prop-types'
import { InfoButton } from 'components/buttons'
import { track, tracking } from 'lib/analytics'

@tracking(({ registry }) => ({ registry }))
class CheckoutButton extends Component {
  @tracking((props, state) => {
    if (props.storeName === 'Babylist') {
      const isFsaHsaOrder = props.cartItems.some((item) => item.fsaHsaEligible)
      const useRegistryDiscount = !!props.useRegistryDiscount
      return {
        event: track.checkoutStarted,
        cartItems: props.cartItems,
        regDiscountApplied: useRegistryDiscount,
        isFsaHsaOrder,
      }
    }
    // reservations context (productIds, regItemIds) is set on ExternalStoreOrder
    return {
      event: track.proceededToBuy,
    }
  })
  handleClick = (e) => {
    const { onCheckoutClick } = this.props
    e.preventDefault()
    onCheckoutClick()
  }

  render() {
    const { href, text, submitting } = this.props

    return (
      <InfoButton
        block
        pill
        href={href}
        onClick={this.handleClick}
        submitting={submitting}
        disabled={submitting}
      >
        {text}
      </InfoButton>
    )
  }
}

CheckoutButton.propTypes = {
  cartItems: PropTypes.any,
  href: PropTypes.any,
  onCheckoutClick: PropTypes.func,
  registry: PropTypes.any,
  reservation: PropTypes.any,
  submitting: PropTypes.bool,
  storeName: PropTypes.any,
  subTotal: PropTypes.any,
  submitting: PropTypes.bool,
  text: PropTypes.any,
  useRegistryDiscount: PropTypes.any,
}

CheckoutButton.defaultProps = {
  submitting: false,
}

export default CheckoutButton
