import React, { useState, useRef } from 'react'
import classNames from 'classnames'

import css from './TextInput.styles.scss'

import { TextInputProps } from './TextInput.types'
import Check from '../../../icons/Check.svg'

const inputSizeClassname = (size: TextInputProps['size']) => {
  switch (size) {
    case 'sm':
      return css.input__small
    case 'md':
      return css.input__medium
    case 'lg':
      return css.input__large
  }
}

const inputTypeClassname = (variant: TextInputProps['variant']) => {
  switch (variant) {
    case 'primary':
      return css.input__primary
    case 'inverted':
      return css.input__inverted
    default:
      return css.input__primary
  }
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  size,
  className,
  controlClassName,
  prefix,
  status,
  RightIcon,
  LeftIcon,
  type,
  variant,
  onFocus = () => {},
  onBlur = () => {},
  ...props
}) => {
  const [focused, setFocused] = useState<boolean>(false)

  const TextInputClasses = classNames(
    css.TextInput,
    inputSizeClassname(size),
    inputTypeClassname(variant),
    {
      [css.input__focused]: focused,
      [css.input__disabled]: props.disabled,
      [css.input__error]: status?.type === 'error',
      [css.input__success]: status?.type === 'success',
    },
    className
  )

  const ControlClasses = classNames(css.input__control, controlClassName)

  // TODO: Once we are on React 18 we can use the useId() hook to generate a unique ID for the tooltip
  // Until then this little trick will convert a random number to a base 36 string
  const [uuid1] = useState<string>(Math.random().toString(36).substring(2))
  const labelMessageId = uuid1
  const [uuid2] = useState<string>(Math.random().toString(36).substring(2))
  const errorMessageId = uuid2

  const inputRef = useRef<HTMLInputElement>(null)
  const onInputControlClick = () => inputRef.current && inputRef.current.focus()

  const shouldShowErrorMessage = status?.type === 'error' && status.message

  return (
    <div className={TextInputClasses}>
      {label && (
        <label htmlFor={labelMessageId} className={css.input__label}>
          {label}
        </label>
      )}
      <div className={ControlClasses} onClick={onInputControlClick}>
        {LeftIcon && (
          <span className={classNames(css.input__icon, css.input__left_icon)}>
            <LeftIcon />
          </span>
        )}
        {prefix && <span className={css.input__prefix}>{prefix}</span>}
        <input
          ref={inputRef}
          id={labelMessageId}
          onFocus={(e) => {
            setFocused(true)
            onFocus(e)
          }}
          onBlur={(e) => {
            setFocused(false)
            onBlur(e)
          }}
          type={type || 'text'}
          className={css.input__text}
          aria-invalid={status?.type === 'error'}
          aria-errormessage={
            shouldShowErrorMessage ? errorMessageId : undefined
          }
          {...props}
        />
        {RightIcon && (
          <span className={classNames(css.input__icon, css.input__right_icon)}>
            <RightIcon />
          </span>
        )}
      </div>
      {shouldShowErrorMessage && (
        <div
          id={errorMessageId}
          className={classNames(css.input__message, css.input__error_message)}
        >
          {status.message}
        </div>
      )}
      {status?.type === 'success' && status.message && (
        <div
          role="status"
          className={classNames(css.input__message, css.input__success_message)}
        >
          <Check /> {status.message}
        </div>
      )}
    </div>
  )
}

export default TextInput
