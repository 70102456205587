import React from 'react'
import classNames from 'classnames'

import css from './Badge.styles.scss'

import { BadgeProps } from './Badge.types'

const Badge: React.FC<BadgeProps> = ({
  label,
  quantity,
  variant,
  icon,
  fill,
  size,
  className,
}) => {
  const BadgeClasses = classNames(css.Badge, className, {
    [css['Badge--small']]: size === 'small',
    [css['Badge--medium']]: size === 'medium',
    [css[`Badge--${fill}`]]: fill,
    [css['Badge--general']]:
      variant === 'general' || variant === 'promo' || variant === 'status',
    [css['Badge--quantity']]: variant === 'quantity',
  })

  const GeneralBadge = (
    <div className={BadgeClasses}>
      {icon && <div className={css['Badge--icon']}>{icon}</div>}
      <span>{label}</span>
    </div>
  )

  const quantityString = () => {
    if (!quantity || quantity < 0) return '0'
    if (quantity <= 99) return Math.round(quantity).toString()
    return '99+'
  }

  const QuantityBadge = (
    <div className={BadgeClasses}>
      <span className={css.Quantity}>{quantityString()}</span>
    </div>
  )

  return <>{variant === 'quantity' ? QuantityBadge : GeneralBadge}</>
}

export default Badge
