/* eslint-disable react/forbid-prop-types */
/* eslint-disable complexity */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { get, mapKeys } from 'lodash'
import {
  Checkbox,
  CountrySelector,
  Input,
  StateSelector,
} from 'components/forms'
import {
  notContainEmoji,
  notEmail,
  notPoBox,
  required,
} from 'lib/form-validation'
import { Col, Row } from 'react-bootstrap'
import { getCityStateByZip } from 'registry/api'
import { PrivateAddressDisclaimer } from './PrivateAddressDisclaimer'

export function createValidationRules({ context, showConsolidationChoice }) {
  let fields = {
    city: [required],
    country: [required],
    name: [required, notEmail, notContainEmoji],
    streetAddress1: [required, notEmail],
    zip: [required],
    state: [
      (state, data) => {
        if (get(data, context ? `${context}.country` : 'country'))
          return required(state, data)
        return undefined
      },
    ],
  }

  if (showConsolidationChoice) {
    fields.consolidationChoice = [required]
  }

  // Context is used to determine the full path of the key in the state
  if (context) {
    fields = mapKeys(fields, (value, key) => `${context}.${key}`)
  }
  return fields
}

const ShippingFields = ({
  city,
  excludedFields = [],
  countries,
  country,
  name: fullName,
  phone,
  states,
  state,
  streetAddress1,
  streetAddress2,
  zip,
  privateAddress,
  isDisabled,
}) => {
  const [showPoBoxWarning, setShowPoBoxWarning] = useState(false)

  const handleStreetChange = (e) => {
    const isPoBox = !!notPoBox(e.target.value)
    setShowPoBoxWarning(isPoBox)
    streetAddress1.onChange(e.target.value)
  }

  const handleCountryChange = (value) => {
    const isChangingToOrFromNorthAmerica =
      (country.value === 'United States' || country.value === 'Canada') !==
      (value === 'United States' || value === 'Canada')

    if (isChangingToOrFromNorthAmerica) {
      state.onChange('')
    }

    country.onChange(value)
  }

  const handleZipChange = (e) => {
    // Only autofill city and state for correct looking US zip
    // with optional 4 digit extension
    const fiveDigitZip = e.target.value.match(/^\d{5}(-\d{4})?$/)

    if (fiveDigitZip) {
      getCityStateByZip(fiveDigitZip[0]).then((resp) => {
        city.onChange(resp.city || city)
        state.onChange(resp.state || state)
      })
    }

    zip.onChange(e.target.value)
  }

  const getValue = (value, initialValue) =>
    value === undefined ? initialValue : value

  return (
    <div>
      {!excludedFields.includes('name') && (
        <Input
          {...fullName}
          value={getValue(fullName?.value, fullName?.initialValue)}
          disabled={isDisabled}
          error={fullName.touched ? fullName.error : null}
          id="full_name"
          label="Full Name"
          type="text"
        />
      )}

      <Row>
        <Col sm={9} xs={9}>
          {!excludedFields.includes('streetAddress1') && (
            <>
              <Input
                {...streetAddress1}
                value={getValue(
                  streetAddress1?.value,
                  streetAddress1?.initialValue
                )}
                disabled={isDisabled}
                error={streetAddress1.touched ? streetAddress1.error : null}
                help={
                  showPoBoxWarning && (
                    <div className="has-error">
                      <div className="flex align-center danger">
                        <i className="fa fa-icn fa-exclamation-circle large prm" />{' '}
                        <span>
                          Many larger items cannot be shipped to PO boxes. If
                          possible, please provide a physical street address.
                        </span>
                      </div>
                    </div>
                  )
                }
                id="street_address"
                label="Street Address"
                type="text"
                onChange={handleStreetChange}
              />
            </>
          )}
        </Col>

        <Col sm={3} xs={3}>
          {!excludedFields.includes('streetAddress2') && (
            <Input
              {...streetAddress2}
              value={streetAddress2?.value}
              disabled={isDisabled}
              id="apt"
              label="Apt/Suite"
              type="text"
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={3} xs={3}>
          {!excludedFields.includes('zip') && (
            <Input
              {...zip}
              value={getValue(zip?.value, zip?.initialValue)}
              disabled={isDisabled}
              error={zip.touched ? zip.error : null}
              id="zip"
              label={country.value === 'United States' ? 'Zip' : 'Postal Code'}
              type="text"
              onChange={handleZipChange}
            />
          )}
        </Col>

        <Col xs={6}>
          {!excludedFields.includes('city') && (
            <Input
              {...city}
              value={getValue(city?.value, city?.initialValue)}
              disabled={isDisabled}
              error={city.touched ? city.error : null}
              id="city"
              label="City"
              type="text"
            />
          )}
        </Col>

        <Col sm={3} xs={3}>
          {!excludedFields.includes('state') && (
            <StateSelector
              {...state}
              value={getValue(state?.value, state?.initialValue)}
              country={country.value || countries[0]}
              error={state.touched ? state.error : null}
              isDisabled={isDisabled}
              states={states}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          {!excludedFields.includes('country') && (
            <CountrySelector
              {...country}
              countries={countries}
              error={country.touched ? country.error : null}
              isDisabled={isDisabled}
              value={
                getValue(country.value, country.initialValue) || countries[0]
              }
              onChange={handleCountryChange}
            />
          )}
        </Col>
        <Col xs={6}>
          {!excludedFields.includes('phone') && (
            <Input {...phone} id="phone" label="Phone" type="text" />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {!excludedFields.includes('privateAddress') && (
            <>
              <Checkbox
                {...privateAddress}
                checked={privateAddress.value}
                label="Do not share my address with my gift givers."
              />
              {privateAddress.value && <PrivateAddressDisclaimer />}
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

ShippingFields.propTypes = {
  city: PropTypes.object.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
  country: PropTypes.object.isRequired,
  excludedFields: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.object.isRequired,
  phone: PropTypes.object.isRequired,
  states: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  state: PropTypes.object.isRequired,
  streetAddress1: PropTypes.object.isRequired,
  streetAddress2: PropTypes.object.isRequired,
  zip: PropTypes.object.isRequired,
  privateAddress: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
}

ShippingFields.defaultProps = {
  excludedFields: [],
  isDisabled: false,
}

export default ShippingFields
