import fetch from 'lib/fetch'
import qs from 'query-string'
import { map, isEmpty } from 'lodash'

import {
  apiV3BrandsPath,
  apiV3BrandProductsPath,
  apiV3BrandsSearchPath,
  apiV3CashFundPreferencesPath,
  apiV3CartItemsPath,
  apiV3PdpPath,
  apiV3ProductsPath,
  apiV3RegItemPath,
  apiV3RegItemsPath,
  apiV3ProductAvailabilitySubscriptionsPath,
  apiV3CatalogCategoriesProductsPath,
  apiV3CatalogCategoriesFacetsPath,
} from 'lib/urls'
import { SOURCE_CATALOG } from 'shared/constants'

export const addCartItem = (cartItem) => {
  return fetch(apiV3CartItemsPath, {
    body: JSON.stringify({ cartItem }),
    method: 'POST',
  })
}

export const getProduct = (id) => fetch(apiV3PdpPath(id))

export const getProducts = ({ categoryIds, count, searchTerm, collection }) => {
  const categoryIdsParam = map(
    categoryIds,
    (id) => `category_ids[]=${id}`
  ).join('&')
  const params = {}

  if (searchTerm) {
    params.search_term = searchTerm
  }

  if (collection) {
    params.collection = collection
  }

  if (count) {
    params.count = count
  }
  return fetch(
    `${apiV3ProductsPath}?${qs.stringify(params)}&${categoryIdsParam}`
  )
}

export const getProductSearchResults = (term) =>
  getProducts({ searchTerm: term })

export const getRegItem = (regItemId, registryId) =>
  fetch(apiV3RegItemPath(regItemId, registryId))

/**
 * @typedef AddToBabylistParams
 * @type {object}
 * @property {string} [title]
 * @property {string} [description]
 * @property {number} [price]
 * @property {number} quantity
 * @property {number} [category_id]
 * @property {number} product_id
 * @property {number} registryId
 *
 * @typedef AddToBabylistOptions
 * @type {object}
 * @property {boolean} includeRecommendation - Include Add Next Recommendation in response.
 *
 * @typedef AddNextRecommendation
 * @type {object}
 * @property {object} metadata
 * @property {object} recommendedProducts
 *
 * @typedef RegItemPayload
 * @type {Object.<*,*>}
 * @property {AddNextRecommendation} addNextRecommendation
 * @property {string} title
 * @property {boolean} isFirstRegItem
 *
 * @typedef RegItemPostResponse
 * @type {object}
 * @property {RegItemPayload} regItem
 */

/**
 * @param {AddToBabylistParams} params
 * @param {object} options
 *
 * @return {Promise<RegItemPostResponse>}
 */
export const addToBabylist = (params, options) => {
  const { registryId, ...regItemFields } = params

  let apiPath = apiV3RegItemsPath(registryId)
  const queryParams = {}

  if (options?.includeRecommendation) {
    queryParams.include_recommendation = true
  }

  if (!isEmpty(queryParams)) {
    apiPath = `${apiPath}?${qs.stringify(queryParams)}`
  }

  return fetch(apiPath, {
    body: JSON.stringify({
      reg_item: {
        source: SOURCE_CATALOG,
        ...regItemFields,
      },
    }),
    method: 'POST',
  })
}

export const saveCashFundPreferences = (data) => {
  return fetch(apiV3CashFundPreferencesPath, {
    body: JSON.stringify(data),
    method: 'POST',
  })
}

export const addProductAvailabilitySubscription = (productId) => {
  return fetch(apiV3ProductAvailabilitySubscriptionsPath, {
    body: JSON.stringify({ product_id: productId }),
    method: 'POST',
  })
}

/**
 * react-query friendy async function for fetching brand.
 *
 * @param {string} _key - react-query key.
 * @param {number} brandId - The id of a brand
 * @param {BrandProductsParams} params - Params for API request.
 * @returns {Promise} Promise from fetch call.
 */
export const fetchBrand = async (_key, brandId) => {
  const apiPath = apiV3BrandsPath(brandId)

  return fetch(`${apiPath}`).then((response) => response.data)
}

/**
 * @typedef {object} BrandProductsParams
 * @property {number} limit – Max number of hero products to retrieve
 */

/**
 * react-query friendy async function for fetching brand products.
 *
 * @param {string} _key - react-query key.
 * @param {number} brandId - The id of a brand
 * @param {BrandProductsParams} params - Params for API request.
 * @returns {Promise} Promise from fetch call.
 */
export const fetchBrandProducts = async (_key, brandId, params) => {
  const apiPath = apiV3BrandProductsPath(brandId)

  return fetch(`${apiPath}?${qs.stringify(params)}`).then(
    (response) => response.data
  )
}

/**
 * react-query friendy async function for fetching brands search.
 *
 * @param {string} _key - react-query key.
 * @param {string} searchTerm
 * @returns {Promise} Promise from fetch call.
 */
export const fetchBrandsSearch = async (_key, searchTerm) => {
  return fetch(apiV3BrandsSearchPath(searchTerm)).then(
    (response) => response.data
  )
}

/**
 * @typedef {object} CatalogCategoriesProductsParams
 * @property {boolean} heroes – Products will come back formatted as Hero Products.
 */

/**
 * react-query friendy async function for fetching catalog category products.
 *
 * @param {string} _key - react-query key.
 * @param {number|string} catalogCategoryId - The id or slug of a category (leaf category).
 * @param {CatalogCategoriesProductsParams} params - Params for API request.
 * @returns {Promise} Promise from fetch call.
 */
export const fetchCatalogCategoryProducts = async (
  _key,
  catalogCategoryId,
  params
) => {
  const apiPath = apiV3CatalogCategoriesProductsPath(catalogCategoryId)

  return fetch(`${apiPath}?${qs.stringify(params)}`).then(
    (response) => response.data
  )
}

export const fetchCatalogCategoryFacets = async (
  _key,
  catalogCategoryId,
  filters
) => {
  const apiPath = apiV3CatalogCategoriesFacetsPath(catalogCategoryId)

  return fetch(`${apiPath}?filters=${qs.stringify(filters)}`).then(
    (response) => response.data
  )
}

/**
 * @typedef {object} ProductsSearchParams
 * @property {boolean} heroes – Products will come back formatted as Hero Products.
 * @property {string} searchTerm
 */

/**
 * react-query friendy async function for fetching products search.
 *
 * @param {string} _key - react-query key.
 * @param {string} searchTerm
 * @param {ProductsSearchParams} params - Params for API request.
 * @returns {Promise} Promise from fetch call.
 */
export const fetchProductsSearch = async (_key, searchTerm, params) => {
  const requestParams = {
    ...params,
    search_term: searchTerm,
  }

  return fetch(`${apiV3ProductsPath}?${qs.stringify(requestParams)}`).then(
    (response) => response.data
  )
}

export const fetchCuratedProductCollections = async () => {
  return fetch('/api/v3/curated_product_collections').then(
    (response) => response
  )
}
