import { Component } from 'react'
import PropTypes from 'prop-types'
import { bowserFlags } from 'lib/bowser'
import cx from 'classnames'

import * as blUrls from 'lib/urls'
import Tappable from 'react-tappable'
import withCurrentUser from 'shared/HOC/withCurrentUser'
import { tracking, track, withContextualizedTracking } from 'lib/analytics'

import { SHOW_MOBILE_NAV_EVENT } from 'shared/constants'
import css from './mobile-tab-bar.scss'

@withContextualizedTracking(() => ({
  eventLocation: track.EventLocation.MOBILE_WEB_BOTTOM_NAV,
}))
class MobileTabBar extends Component {
  constructor(props) {
    super(props)
    const { isVisible } = props
    this.state = { isVisible: isVisible || false }
  }

  componentDidMount() {
    PubSub.subscribe(SHOW_MOBILE_NAV_EVENT, (event) => {
      if (bowserFlags.mobile) {
        this.setState({ isVisible: true })
      }
    })

    //bootstrap
    setTimeout(() => {
      PubSub.publish(SHOW_MOBILE_NAV_EVENT)
    }, 1)
  }

  handleSignUpClick = () => {
    window.location.href = `${blUrls.newRegistryPath}?loc=${track.EventLocation.MOBILE_WEB_BOTTOM_NAV}`
  }

  render() {
    const { isVisible } = this.state
    if (!isVisible) {
      return null
    }
    const {
      isHomePage,
      isAddItemsPage,
      isRegistryPage,
      isHelloBabyPage,
      isStorePage,
      currentUser,
    } = this.props
    const homeUrl = currentUser ? blUrls.registryHomePath : blUrls.indexPath
    const registryUrl = currentUser
      ? blUrls.registryPath(currentUser?.currentRegistry?.urlSlug)
      : blUrls.whyBabyListPath

    return (
      <div className={css.affixMobileNav}>
        <div className={css.mobileMenuContainer}>
          <Tappable
            className={cx({ [css.active]: isHomePage, [css.tabButton]: true })}
            component="a"
            href={homeUrl}
          >
            <div className={cx(css.iconMobileNav, css.iconHome)} />
            <span className={css.iconText}>Home</span>
          </Tappable>
          <Tappable
            className={cx({
              [css.active]: isRegistryPage,
              [css.tabButton]: true,
            })}
            component="a"
            href={registryUrl}
          >
            <div className={cx(css.iconMobileNav, css.iconRegistry)} />
            <span className={css.iconText}>Registry</span>
          </Tappable>
          {!currentUser ? (
            <Tappable onTap={this.handleSignUpClick}>
              <div className={css.signUpButton}>
                <div className={cx(css.iconSignUpHeart)} />
                <span>Sign Up</span>
              </div>
            </Tappable>
          ) : (
            <Tappable
              className={cx({
                [css.active]: isAddItemsPage,
                [css.tabButton]: true,
              })}
              component="a"
              href={blUrls.registryAddItemsPath}
            >
              <div className={cx(css.iconMobileNav, css.iconAnyStore)} />
              <span className={css.iconText}>Add Items</span>
            </Tappable>
          )}
          <Tappable
            className={cx({
              [css.active]: isHelloBabyPage,
              [css.tabButton]: true,
            })}
            component="a"
            href={blUrls.helloBabyPath}
          >
            <div className={cx(css.iconMobileNav, css.iconGuides)} />
            <span className={css.iconText}>Guides</span>
          </Tappable>
          <Tappable
            className={cx({ [css.active]: isStorePage, [css.tabButton]: true })}
            component="a"
            href={blUrls.catalogPath}
          >
            <div className={cx(css.iconMobileNav, css.iconStore)} />
            <span className={css.iconText}>Shop</span>
          </Tappable>
        </div>
      </div>
    )
  }
}

MobileTabBar.propTypes = {
  isRegistryPage: PropTypes.bool,
  isAddItemsPage: PropTypes.bool,
  isHelloBabyPage: PropTypes.bool,
  isHomePage: PropTypes.bool,
  isStorePage: PropTypes.bool,
  isVisible: PropTypes.bool,
  currentUser: PropTypes.object,
}

export default withCurrentUser(MobileTabBar)
