import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Modal } from 'react-overlays'

// @FIXME: importing default in storybook is crapping out.
// work around is to use require and grab default
const Card = require('components/card').default
import { CloseButton } from 'components/buttons'

import { Backdrop } from './modal'
import css from './modal.scss'

const BubbleContentCard = ({ className, children }) => (
  <Card className={classNames(`bg-white`, className)}>{children}</Card>
)

export const BubbleModalHeader = ({
  children,
  onHide,
  className,
  ...contentCardProps
}) => (
  <BubbleContentCard className="mbm" {...contentCardProps}>
    <div className="flex space-between">
      <div className={className}>{children}</div>
      <div className="flex center prs">
        <CloseButton onClick={onHide} style={{ marginTop: '-2px' }} />
      </div>
    </div>
  </BubbleContentCard>
)

BubbleModalHeader.propTypes = {
  children: PropTypes.node,
  onHide: PropTypes.func,
}

export const BubbleModalBody = ({ children, ...contentCardProps }) => (
  <BubbleContentCard {...contentCardProps}>{children}</BubbleContentCard>
)

export const BubbleModal = ({ children, ...modalProps }) => {
  const Header = cloneElement(
    children.find(({ type }) => type === BubbleModalHeader),
    { onHide: modalProps.onHide }
  )
  const Body = children.find(({ type }) => type === BubbleModalBody)

  return (
    <Modal renderBackdrop={Backdrop} className={css.modal} {...modalProps}>
      <div className={css.flexDialogContainer}>
        <div className={classNames(css.flexDialogContent, `pal`)}>
          {Header}
          {Body}
          {/* Need a spacer element to apply bottom padding. */}
          <div className="pbl" />
        </div>
      </div>
    </Modal>
  )
}

/**
 * Manually declare consumed PropTypes so storybook can statically
 * parse intended type for documentation vs extending with Modal.propTypes.
 */
BubbleModal.propTypes = {
  children: PropTypes.node,
  onHide: PropTypes.func,
  show: PropTypes.bool,
}

BubbleModal.defaultProps = {
  show: false,
  onHide: () => {},
}

BubbleModal.Header = BubbleModalHeader
BubbleModal.Body = BubbleModalBody
