import * as React from 'react'
import classnames from 'classnames'
import Chip from '../Chip'

export interface FTCDisclosureTagProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  /**
   * Packs the tag flush to the provided edge of the container.
   * @default 'right'
   */
  align?: 'left' | 'right'
  /**
   * Specifies which particular message to display.
   * @default "registry"
   */
  disclaimerContext?: 'registry' | 'guide'
}

export default function FTCDisclosureTag({
  align = 'right',
  className,
  disclaimerContext = FTCDisclosureTag.Constants
    .REGISTRY as FTCDisclosureTagProps['disclaimerContext'],
  ...rest
}: FTCDisclosureTagProps) {
  return (
    <div
      className={classnames(
        'flex',
        { 'justify-end': align === 'right', 'justify-start': align === 'left' },
        className
      )}
      {...rest}
    >
      <Chip label={messages[disclaimerContext as any]} />
    </div>
  )
}

FTCDisclosureTag.Constants = {
  GUIDE: 'guide',
  MODAL: 'modal',
  REGISTRY: 'registry',
}

const messages = {
  [FTCDisclosureTag.Constants.GUIDE]:
    'Babylist editors love baby gear and independently curate their favorite products to share with you. If you buy something through links on our site, Babylist may earn a commission.',
  [FTCDisclosureTag.Constants.REGISTRY]:
    'If you buy something through a partner retailer, Babylist may earn a commission',
  [FTCDisclosureTag.Constants.MODAL]:
    'Babylist may earn a commission on purchases',
}
