import { useState } from 'react'
import { addProductAvailabilitySubscription } from '../../api'
import { LinkButton } from '../../../components/buttons'
//@ts-ignore
import { errorMessage, successMessage } from 'lib/flash-message'
import { track, useTracking } from 'lib/analytics/track'

export const CONFIRMATION_MESSAGE =
  "We'll email you when this item is back in stock!"

interface InStockAlertLinksProps {
  productId: number
}

export default function InStockAlertLink({
  productId,
}: InStockAlertLinksProps) {
  const [addingInStockAlert, setAddingInStockAlert] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const tracker = useTracking()

  const handleClick = () => {
    setAddingInStockAlert(true)
    const trackingEvent = {
      eventType: track.EventType.CLICK,
      event: track.stockNotificationRequestClicked,
    }
    tracker.trackEvent(trackingEvent)

    addProductAvailabilitySubscription(productId)
      .then(() => {
        setIsSubscribed(true)
        successMessage(CONFIRMATION_MESSAGE)
      })
      .catch(() => {
        setIsSubscribed(false)
        errorMessage()
      })
      .finally(() => setAddingInStockAlert(false))
  }

  return (
    <div className="small pvs flex align-center">
      {!isSubscribed ? (
        <>
          <LinkButton
            className="mrs pan link-info text-bold"
            onClick={handleClick}
            submitting={addingInStockAlert}
          >
            Get Notified
          </LinkButton>{' '}
          when this item is in stock.
        </>
      ) : (
        <>
          <i className="fa fa-check text-success mrs" />
          {CONFIRMATION_MESSAGE}
        </>
      )}
    </div>
  )
}
