import React from 'react'
import classNames from 'classnames'

import css from './QuantityPicker.styles.scss'

import { QuantityPickerProps } from './QuantityPicker.types'
import { GarbageBin, Plus, Minus } from '../../icons'

const QuantityPicker: React.FC<QuantityPickerProps> = ({
  value,
  min = 1,
  max,
  onPlusClick = () => {},
  onMinusClick = () => {},
  onTrashClick = () => {},
  variant,
  type,
  size,
  className,
}) => {
  const QuantityPickerClasses = classNames(
    css.quantity_picker,
    css[`quantity_picker--variant-${variant}`],
    css[`quantity_picker--size-${size}`],
    className
  )

  const displayValue = value === null ? '∞' : value

  const isMinValue = value === null || value <= min

  const isMaxValue = value === null ? false : !!max && value >= max

  const shouldDisplayTrash = type === 'cart' && isMinValue

  return (
    <div className={QuantityPickerClasses}>
      {shouldDisplayTrash ? (
        <button
          onClick={() => onTrashClick(value)}
          className={css.quantity_picker__button}
          aria-label="Trash"
        >
          <GarbageBin className={css.quantity_picker__trash} />
        </button>
      ) : (
        <button
          disabled={isMinValue}
          onClick={() => onMinusClick(value as number)}
          className={css.quantity_picker__button}
          aria-label="Minus"
        >
          <Minus className={css.quantity_picker__minus} />
        </button>
      )}
      <div className={css.quantity_picker__quantity} aria-label="Quantity">
        {displayValue}
      </div>
      <button
        disabled={isMaxValue}
        onClick={() => onPlusClick(value as number)}
        className={css.quantity_picker__button}
        aria-label="Plus"
      >
        <Plus className={css.quantity_picker__plus} />
      </button>
    </div>
  )
}

export default QuantityPicker
