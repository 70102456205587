import Proptypes from 'prop-types'
import { registryDiscountPath } from 'lib/urls'

const DiscountDisclaimer = () => (
  <a
    className="tiny"
    href={registryDiscountPath}
    rel="noopener noreferrer"
    target="_blank"
  >
    **Restrictions may apply.
  </a>
)

export const ShippingDisclaimer = ({ className }) => (
  <a
    className={`tiny ${className}`}
    href="https://help.babylist.com/hc/en-us/articles/217912848-How-much-is-shipping-at-the-Babylist-store-"
    rel="noopener noreferrer"
    style={{ display: 'inline-block' }}
    target="_blank"
  >
    *Restrictions may apply. Not all products are eligible.
  </a>
)

ShippingDisclaimer.propTypes = {
  className: Proptypes.string,
}

ShippingDisclaimer.defaultProps = {
  className: '',
}

const Disclaimers = () => (
  <div style={{ textAlign: 'center' }}>
    <DiscountDisclaimer />
    <br />
    <ShippingDisclaimer />
  </div>
)

export default Disclaimers
