import PropTypes from 'prop-types'
import Link from 'components/link'
import { LinkButton, InfoButton } from 'components/buttons'
import AddToCartButton from 'store/components/add-to-cart-button'
import InStockAlertButton from 'store/components/in-stock-alert-button'
import { GROUP_GIFT_ACTIONS } from 'registry/constants'
import OwnerActions from '../owner-actions/owner-actions'
import GroupGiftOfferPanel from './group-gift-offer-panel'
import RegItemCardLayout from '../reg-item-card-layout'
import { regItemProps } from '../reg-item-props'
import EditRegItemLink from '../edit-reg-item-link'
import { settingsGiftCardsAndPromotionsPath } from 'lib/urls'

const ManageFundsButton = ({
  isAvailableOnBabylist,
  isBabylistProductDiscontinued,
  hasContributions,
  openGroupGiftRedemptionModal,
}) => {
  let button

  if (isBabylistProductDiscontinued) {
    button = (
      <InfoButton
        pill
        onClick={() => openGroupGiftRedemptionModal(GROUP_GIFT_ACTIONS.DELETE)}
      >
        Redeem Funds &amp; Remove Item
      </InfoButton>
    )
  } else if (!isAvailableOnBabylist) {
    button = (
      <LinkButton
        className="phn bold text-secondary"
        onClick={() => openGroupGiftRedemptionModal(GROUP_GIFT_ACTIONS.DELETE)}
      >
        Redeem Funds &amp; Remove Item
      </LinkButton>
    )
  } else if (hasContributions) {
    button = (
      <LinkButton
        pill
        className="phn bold text-secondary"
        href={settingsGiftCardsAndPromotionsPath}
      >
        View &amp; Manage Group Gift Funds
      </LinkButton>
    )
  }

  return button ? <div>{button}</div> : null
}

ManageFundsButton.propTypes = {
  isAvailableOnBabylist: PropTypes.bool.isRequired,
  isBabylistProductDiscontinued: PropTypes.bool.isRequired,
  hasContributions: PropTypes.bool.isRequired,
  regItemId: PropTypes.number.isRequired,
  openGroupGiftRedemptionModal: PropTypes.func.isRequired,
}

const ContributorGroupGiftActions = ({
  id,
  fundedAmount,
  isAvailableOnBabylist,
  isBabylistProductDiscontinued,
  isRegistryDiscountView,
  productId,
  openGroupGiftRedemptionModal,
  quantityPurchased,
}) => {
  const isPurchased = quantityPurchased > 0
  const AddToCartAction = () => {
    if (fundedAmount === 0) {
      return (
        <AddToCartButton
          productId={productId}
          quantity={1}
          useInfoButtonStyle
          buttonStyle={{ pill: true, block: false }}
          style={{ minWidth: 200 }}
        />
      )
    }
    // Open redemption modal with add to cart
    return (
      <InfoButton
        pill
        onClick={() =>
          openGroupGiftRedemptionModal(
            isRegistryDiscountView
              ? GROUP_GIFT_ACTIONS.QUICKADD
              : GROUP_GIFT_ACTIONS.CART
          )
        }
        style={{ minWidth: 200 }}
      >
        Add to Cart
      </InfoButton>
    )
  }

  return (
    <div className="mtl">
      {isBabylistProductDiscontinued && (
        <em className="block pbl h7">
          <strong>We're sorry. </strong>Unfortunately this item has been
          discontinued and is no longer available for purchase.
        </em>
      )}
      {!isBabylistProductDiscontinued &&
        !isAvailableOnBabylist &&
        !isPurchased && (
          <InStockAlertButton
            text="In stock soon - get in-stock alert"
            buttonStyle={{ pill: true, empty: false, block: false }}
            productId={productId}
          />
        )}
      {!isBabylistProductDiscontinued &&
        isAvailableOnBabylist &&
        !isPurchased &&
        AddToCartAction()}
      {!isPurchased && (
        <ManageFundsButton
          regItemId={id}
          isAvailableOnBabylist={isAvailableOnBabylist}
          isBabylistProductDiscontinued={isBabylistProductDiscontinued}
          hasContributions={fundedAmount > 0}
          openGroupGiftRedemptionModal={openGroupGiftRedemptionModal}
        />
      )}
    </div>
  )
}

ContributorGroupGiftActions.propTypes = {
  id: PropTypes.number.isRequired,
  fundedAmount: PropTypes.number,
  isAvailableOnBabylist: PropTypes.bool.isRequired,
  isBabylistProductDiscontinued: PropTypes.bool.isRequired,
  isRegistryDiscountView: PropTypes.bool.isRequired,
  productId: PropTypes.number.isRequired,
  openGroupGiftRedemptionModal: PropTypes.func.isRequired,
}

ContributorGroupGiftActions.defaultProps = {
  fundedAmount: 0,
}

const ContributorGroupGiftRegItemCard = (props) => {
  const {
    isMobile,
    isRegistryDiscountView,
    regItem,
    openGroupGiftRedemptionModal,
  } = props

  const regItemLink = (child) => {
    if (isMobile && regItem.isEditable) {
      return <EditRegItemLink regItemId={regItem.id}>{child}</EditRegItemLink>
    }
    return (
      <Link className="link-info" url={regItem.url} rel="nofollow" newWindow>
        {child}
      </Link>
    )
  }

  const offerPanel = (
    <>
      <GroupGiftOfferPanel withOfferList {...props} />
      <ContributorGroupGiftActions
        {...regItem}
        openGroupGiftRedemptionModal={openGroupGiftRedemptionModal}
        isRegistryDiscountView={isRegistryDiscountView}
      />
    </>
  )

  return (
    <RegItemCardLayout
      {...props}
      actions={
        !isRegistryDiscountView && (
          <OwnerActions showEditAction regItemId={regItem.id} />
        )
      }
      regItemLinkDecorator={regItemLink}
      offerPanel={offerPanel}
    />
  )
}

ContributorGroupGiftRegItemCard.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isRegistryDiscountView: PropTypes.bool.isRequired,
  regItem: PropTypes.shape(regItemProps).isRequired,
  openGroupGiftRedemptionModal: PropTypes.func.isRequired,
}

export default ContributorGroupGiftRegItemCard
