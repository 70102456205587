import React from 'react'
import classNames from 'classnames'
import css from './Filter.styles.scss'

import { FilterProps } from './Filter.types'

const filterSizeClassname = (size: FilterProps['size']) => {
  switch (size) {
    case 'sm':
      return css.filter__small
    case 'md':
      return css.filter__medium
    case 'lg':
      return css.filter__large
  }
}

const filterTypeClassname = (type: FilterProps['type']) => {
  switch (type) {
    case 'primary':
      return css.filter__primary
    case 'secondary':
      return css.filter__secondary
    case 'primary_dark_mode':
      return css.filter__primary_dark_mode
    case 'secondary_dark_mode':
      return css.filter__secondary_dark_mode
  }
}

const filterVariantClassname = (variant: FilterProps['variant']) => {
  switch (variant) {
    case 'fill':
      return css.filter__fill
    case 'outline':
      return css.filter__outline
  }
}

const Filter: React.FC<FilterProps> = ({
  LeftIcon = undefined,
  RightIcon = undefined,
  children,
  variant,
  type,
  size,
  onClick = () => {},
  disabled = false,
  className,
  isPressed = false,
}) => {
  const filterClasses = classNames(
    css.filter,
    className,
    filterSizeClassname(size),
    filterTypeClassname(type),
    filterVariantClassname(variant)
  )

  return (
    <button
      className={filterClasses}
      disabled={disabled}
      onClick={onClick}
      aria-pressed={isPressed}
    >
      {LeftIcon && <div className={css.left_icon}>{LeftIcon}</div>}
      {children}
      {RightIcon && <div className={css.right_icon}>{RightIcon}</div>}
    </button>
  )
}

export default Filter
