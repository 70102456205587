import { Component } from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import { withRouter } from 'react-router'
import { helloBabyPostPath } from '../../../lib/urls'
import { Select } from 'components/forms'

class PregnancyWeekSelect extends Component {
  render() {
    let { posts, className } = { ...this.props }
    return (
      <Select
        wrapperClassName={className}
        variant="muted"
        size="small"
        onChange={this.handleChangeSort.bind(this)}
        defaultValue={'jump'}
      >
        <option key={'jump'} value={'jump'}>
          Jump to your pregnancy week
        </option>
        {map(posts, (post) => (
          <option key={post.title} value={helloBabyPostPath(post.slug)}>
            {post.title}
          </option>
        ))}
      </Select>
    )
  }

  handleChangeSort(e) {
    this.props.router.push(e.target.value)
  }
}

PregnancyWeekSelect.propTypes = {
  posts: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default withRouter(PregnancyWeekSelect)
