import React from 'react'

interface AmazonStoreProps {
  className?: string
  rounded?: boolean
}

const AmazonStore: React.FC<AmazonStoreProps> = ({
  className,
  rounded = true,
}: AmazonStoreProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="#121112"
      />
    ) : (
      <rect width="24" height="24" fill="#121112" />
    )}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7425 8.8868C12.3398 8.91701 11.8767 8.94721 11.4135 9.00762C10.7037 9.10327 9.99389 9.22409 9.40993 9.50096C8.26717 9.9641 7.49695 10.9508 7.49695 12.4006C7.49695 14.2229 8.66991 15.1492 10.15 15.1492C10.6433 15.1492 11.046 15.0888 11.4135 14.9932C12.0025 14.8069 12.4959 14.4696 13.0798 13.8504C13.4171 14.3135 13.5128 14.53 14.0967 15.0234C14.2528 15.0838 14.4038 15.0838 14.5297 14.9932C14.9022 14.6861 15.5516 14.1273 15.8889 13.8202C16.045 13.6944 16.0147 13.5131 15.9191 13.3571C15.5818 12.9241 15.2395 12.5516 15.2395 11.721V8.95225C15.2395 7.7793 15.3351 6.69698 14.4693 5.89656C13.7594 5.24716 12.6469 5.00049 11.781 5.00049H11.4085C9.83783 5.0911 8.17153 5.7707 7.799 7.7189C7.73859 7.96557 7.92485 8.06121 8.04567 8.09142L9.77239 8.30788C9.95865 8.27768 10.0493 8.12162 10.0795 7.9706C10.2355 7.291 10.7893 6.95371 11.4035 6.88827H11.5293C11.9018 6.88827 12.2995 7.04433 12.516 7.35141C12.7627 7.72393 12.7325 8.21727 12.7325 8.6502L12.7425 8.8868ZM12.4002 12.5617C12.1838 12.9946 11.8112 13.2715 11.4135 13.3671C11.3531 13.3671 11.2575 13.3973 11.1669 13.3973C10.4872 13.3973 10.0895 12.8738 10.0895 12.0986C10.0895 11.1119 10.6735 10.6487 11.4135 10.4323C11.8163 10.3417 12.2794 10.3064 12.7425 10.3064V10.6789C12.7425 11.3887 12.7727 11.9425 12.4002 12.5617Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9612 16.322C16.8907 16.322 16.8202 16.3371 16.7548 16.3673C16.6793 16.3975 16.6037 16.4328 16.5333 16.463L16.4275 16.5083L16.2916 16.5637C14.7914 17.1728 13.2158 17.5302 11.7558 17.5604C11.7005 17.5604 11.6501 17.5604 11.5948 17.5604C9.29917 17.5604 7.42647 16.4982 5.53866 15.4461C5.47321 15.4109 5.40273 15.3958 5.33729 15.3958C5.25171 15.3958 5.16613 15.426 5.10068 15.4864C5.03524 15.5468 5 15.6324 5 15.723C5 15.8388 5.06041 15.9445 5.14599 16.0099C6.91802 17.5504 8.8612 18.98 11.4739 18.98C11.5243 18.98 11.5746 18.98 11.63 18.98C13.2913 18.9448 15.169 18.381 16.6289 17.4648L16.639 17.4597C16.8303 17.344 17.0216 17.2131 17.2028 17.0721C17.3136 16.9916 17.3941 16.8607 17.3941 16.7248C17.384 16.4831 17.1827 16.322 16.9612 16.322ZM18.9849 15.4713C18.9799 15.3253 18.9497 15.2145 18.8893 15.1239L18.8842 15.1139L18.8792 15.1038C18.8188 15.0383 18.7584 15.0132 18.6929 14.983C18.5016 14.9075 18.2247 14.8672 17.8875 14.8672C17.6458 14.8672 17.379 14.8924 17.1122 14.9477V14.9276L16.8454 15.0182H16.8403L16.6893 15.0686V15.0736C16.5131 15.1491 16.347 15.2397 16.196 15.3505C16.1003 15.4209 16.0248 15.5116 16.0198 15.6575C16.0198 15.7331 16.055 15.8237 16.1205 15.874C16.1859 15.9243 16.2614 15.9445 16.3269 15.9445C16.342 15.9445 16.3571 15.9445 16.3722 15.9394H16.3873H16.3973C16.5282 15.9092 16.7195 15.8941 16.9461 15.8589C17.1374 15.8388 17.3438 15.8237 17.52 15.8237C17.6458 15.8237 17.7566 15.8337 17.8371 15.8488C17.8774 15.8589 17.9026 15.869 17.9177 15.874C17.9227 15.874 17.9277 15.879 17.9277 15.879C17.9328 15.8891 17.9378 15.9193 17.9328 15.9546C17.9328 16.1005 17.8724 16.3774 17.7868 16.6442C17.7012 16.911 17.6005 17.1829 17.5351 17.3591C17.52 17.3993 17.5099 17.4446 17.5099 17.495C17.5099 17.5655 17.5401 17.6561 17.6005 17.7114C17.6609 17.7668 17.7415 17.792 17.8069 17.792H17.8119C17.9126 17.792 17.9932 17.7517 18.0687 17.6963C18.7533 17.0822 18.9899 16.1005 19 15.5518L18.9849 15.4713Z"
      fill="#F4981A"
    />
  </svg>
)

export default AmazonStore
