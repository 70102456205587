import { Component } from 'react'
import PropTypes from 'prop-types'
import Tappable from 'react-tappable/lib/Tappable'
import Image from 'components/image'
import Link from 'components/link'
import classNames from 'classnames'
import { isTouchDevice } from 'lib/touch'
import { tracking, track } from 'lib/analytics'
import {
  getSearchId,
  PRODUCT_SEARCH_INDEX_KEY,
} from 'shared/hooks/useSearchId/useSearchId'
import PriceDisplay from '../price-display'
import css from './product-card.scss'
import { ProductCallouts } from 'components/ProductCallouts/ProductCallouts'
import { getSearchPreviewIds } from 'components/global-nav/components/SearchBar/SearchBar.utils'
import { StarRating } from 'baby-design'

const Badge = ({ imgUrl, alt }) => {
  return (
    <div className={css.badgeImage}>
      <Image src={imgUrl} alt={alt} lazyLoad />
    </div>
  )
}

Badge.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

@tracking((props, state) => {
  const productPrice = parseFloat(props.price?.min)
  const productSaleType = props.priceDetails?.saleAttributes?.saleType
  const { isRegistryOwner, registryId, registryOwnerId } = props
  const searchId = getSearchId(PRODUCT_SEARCH_INDEX_KEY)
  const searchPreviewIds = getSearchPreviewIds()
  const eventPayload = {
    event: track.productClicked,
    isRegistryOwner,
    isFsaHsaEligible: props.fsaHsaEligible,
    positionInList: props.position,
    productIds: [props.productId],
    productPrice: Number.isNaN(productPrice) ? undefined : productPrice,
    productType: props.isElectronicGiftCard
      ? track.ProductType.GIFT_CARD
      : track.ProductType.PRODUCT,
    registryId,
    registryOwnerId,
    searchId,
    searchPreviewIds,
    ...(productSaleType && { saleType: [productSaleType] }),
  }

  return eventPayload
})
class ProductCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isTouchDevice: null,
    }
  }

  componentDidMount() {
    this.setState({
      isTouchDevice: isTouchDevice(),
    })
  }

  hasReviews = () => {
    const { reviewCount } = this.props
    return !!reviewCount
  }

  supportsRatings = () => {
    const { isCashFund, isCrowdfund, isFavor, isElectronicGiftCard } =
      this.props
    return !isCashFund && !isFavor && !isElectronicGiftCard && !isCrowdfund
  }

  render() {
    const {
      actionButton,
      slugSet,
      brand,
      callouts,
      className,
      imageUrl,
      isElectronicGiftCard,
      onClick,
      path,
      price,
      rating,
      reactRouterEnabled,
      reviewCount,
      title,
      merchandisingMessage,
      optionsCta,
      badgeComponent,
      lazyLoad,
    } = this.props
    const { isTouchDevice } = this.state

    const handleClick = (e) => {
      if (onClick) {
        onClick(e)
      }
    }

    return (
      <Tappable
        component={Link}
        classes={{ active: css.activeTappable }}
        title={`${brand} ${title}`}
        to={
          reactRouterEnabled && {
            pathname: path,
            state: { slugSet },
          }
        }
        url={!reactRouterEnabled ? path : undefined}
        newWindow={!reactRouterEnabled}
        onClick={handleClick}
      >
        <div
          className={classNames(className, css.productCard, {
            [css.hoverEnabled]: !isTouchDevice,
          })}
        >
          <div
            className={classNames(
              'hover-overlay hover-darken',
              css.imageWrapper
            )}
          >
            <div className={css.image}>
              <Image
                role="presentation"
                className="img-responsive center-block"
                alt={`${brand} ${title}`}
                src={imageUrl}
                lazyLoad={lazyLoad}
              />

              {badgeComponent && (
                <div className={css.upperRightQuadrant}>{badgeComponent}</div>
              )}
            </div>
          </div>

          <div className={classNames(css.contentWrapper, 'flex col')}>
            <h3 className="flex col unset-all">
              <div className={css.brand}>{brand} </div>
              <div className={css.title} title={title}>
                {title}
              </div>
            </h3>
            {optionsCta && <div className="xsmall">{optionsCta}</div>}

            {this.supportsRatings() && this.hasReviews() ? (
              <StarRating
                className={css.rating}
                rating={rating}
                reviewCount={reviewCount}
              />
            ) : null}
            <div className={css.priceWrapper}>
              <div className={css.priceOptions}>
                {price || isElectronicGiftCard ? (
                  <PriceDisplay
                    isElectronicGiftCard={isElectronicGiftCard}
                    price={price}
                    priceClassName={css.price}
                    msrpClassName={classNames(css.msrp, 'block')}
                    showPriceRange
                  />
                ) : null}
              </div>
              {merchandisingMessage && (
                <div className={css.availabilityMessage}>
                  {merchandisingMessage}
                </div>
              )}
            </div>
            <ProductCallouts callouts={callouts} textSize={13} />
            {actionButton && (
              <div className="flex align-end grow">{actionButton}</div>
            )}
          </div>
        </div>
      </Tappable>
    )
  }
}

ProductCard.propTypes = {
  // Passed in to Tappable as state prop
  slugSet: PropTypes.array,
  brand: PropTypes.string,
  className: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  isCashFund: PropTypes.bool.isRequired,
  isCrowdfund: PropTypes.bool.isRequired,
  isElectronicGiftCard: PropTypes.bool.isRequired,
  isFavor: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  isRegistryOwner: PropTypes.bool,
  onClick: PropTypes.func,
  path: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  position: PropTypes.number.isRequired,
  price: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  rating: PropTypes.number,
  reactRouterEnabled: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  registryId: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  registryOwnerId: PropTypes.number,
  reviewCount: PropTypes.number,
  title: PropTypes.string.isRequired,
  merchandisingMessage: PropTypes.string,
  optionsCta: PropTypes.string,
  badgeComponent: PropTypes.node,
  tracking: PropTypes.object,
  lazyLoad: PropTypes.bool,
}

ProductCard.defaultProps = {
  slugSet: [],
  brand: null,
  className: '',
  isRegistryOwner: false,
  registryId: null,
  registryOwnerId: null,
  onClick: () => {},
  price: null,
  rating: null,
  reactRouterEnabled: true,
  reviewCount: null,
  merchandisingMessage: null,
  optionsCta: null,
  badgeComponent: null,
  tracking: {},
  lazyLoad: true,
}

export default ProductCard

ProductCard.Badge = Badge
