import React from 'react'
import classNames from 'classnames'
import css from './Banner.styles.scss'
import {
  BannerButton,
  BannerImage,
  BannerProps,
  BannerTextLink,
} from './Banner.types'
import Close from '../../icons/Close.svg'
import Button from '../Button/Button'
import TextLink from '../TextLink/TextLink'

interface BannerImageProps {
  icon?: React.ReactNode
  image?: BannerImage
  placement: 'left' | 'right'
}

const BannerGraphicContainer: React.FC<BannerImageProps> = ({
  icon,
  image,
  placement,
}) => {
  return (
    <>
      {image && image.imageSrc && (
        <div
          className={classNames(css.Banner__image, [
            css[`Banner__image--${placement}`],
          ])}
          data-testid="banner-image"
        >
          <img src={image.imageSrc} alt={image.imageAlt} />
        </div>
      )}
      {icon && (
        <div
          className={classNames(css.Banner__icon, [
            css[`Banner__icon--${placement}`],
          ])}
          data-testid="banner-icon"
        >
          {icon}
        </div>
      )}
    </>
  )
}

interface BannerActionsProps {
  button?: BannerButton & {
    buttonSize?: 'sm' | 'md' | 'lg'
    buttonVariant?: 'primary' | 'secondary' | 'tertiary' | 'inverted'
  }
  textLink?: BannerTextLink
}

const BannerActions: React.FC<BannerActionsProps> = ({ button, textLink }) => {
  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) =>
    button?.onClick && button?.onClick(e)

  const handleTextLinkClick = (e: React.MouseEvent<HTMLElement>) =>
    textLink?.onClick && textLink?.onClick(e)

  return (
    <div className={css.Banner__cta} data-testid="banner-actions">
      {button && (
        <Button
          variant={button.buttonVariant}
          size={button.buttonSize || 'sm'}
          onClick={handleButtonClick}
          href={button.href}
        >
          {button.buttonText}
        </Button>
      )}
      {textLink && textLink.textLinkUrl && (
        <TextLink
          href={textLink.textLinkUrl}
          variant={textLink.textLinkVariant}
          size="lg"
          onClick={handleTextLinkClick}
        >
          {textLink.textLinkText}
        </TextLink>
      )}
    </div>
  )
}

const Banner: React.FC<BannerProps> = ({
  className,
  variant,
  color,
  dismissable,
  onClose,
  headline,
  bodyCopy,
  graphic,
  button,
  textLink,
}) => {
  const handleClose = () => {
    onClose && onClose()
  }

  const BannerClasses = classNames(css.Banner, className, {
    [css[`Banner--${variant}`]]: variant,
    [css[`Banner--${color}`]]: color,
    [css['Banner--dismissable']]: dismissable,
  })

  const ContainerClasses = classNames(css.Banner__contentContainer, {
    [css[`Banner__image--${graphic?.placement}`]]: graphic !== undefined,
  })

  const isUltraCompact = variant === 'ultra-compact'
  const isTall = variant === 'tall'

  return (
    <div className={BannerClasses} data-testid="banner">
      <div className={ContainerClasses} data-testid="banner-container">
        {graphic && <BannerGraphicContainer {...graphic} />}
        <div
          className={css.Banner__content}
          data-testid="banner-content-container"
        >
          <div className={css.Banner__textContent}>
            {headline && <div className={css.Banner__headline}>{headline}</div>}
            <div className={css.Banner__bodyCopy}>{bodyCopy}</div>
          </div>
          {!isUltraCompact && (
            <BannerActions button={button} textLink={textLink} />
          )}
        </div>
        {!isTall && button && (
          <BannerActions button={button} textLink={textLink} />
        )}
      </div>
      {dismissable && (
        <button
          className={css.Banner__closeButton}
          data-testid="dismiss-button"
          onClick={handleClose}
        >
          <Close />
        </button>
      )}
    </div>
  )
}

export default Banner
