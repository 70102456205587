import { Cart } from 'src/types/cart'
// @ts-ignore
import RegistryDiscountDisplay from 'cart/components/store-order-summary/components/registry-discount-display'
import css from './CartSummary.styles.scss'
import { CompactCartSummary } from './CompactSummary/CompactSummary'
import { FullSummary } from './FullSummary/FullSummary'
interface CartSummaryProps extends Cart {
  isCompact?: boolean
}

export const CartSummary = ({
  isCompact,
  checkoutTotals,
  isEligibleForRegistryDiscount,
}: CartSummaryProps) => {
  return (
    <div className={css.CartSummary}>
      <div className={css.CartSummary__list}>
        {isCompact ? (
          <CompactCartSummary checkoutTotals={checkoutTotals} />
        ) : (
          <FullSummary
            checkoutTotals={checkoutTotals}
            isEligibleForRegistryDiscount={isEligibleForRegistryDiscount}
          />
        )}
      </div>
    </div>
  )
}
