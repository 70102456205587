import { Cart } from 'src/types/cart'
// @ts-ignore
import RegistryDiscountDisplay from 'cart/components/store-order-summary/components/registry-discount-display'
import { FREE_SHIPPING_THRESHOLD } from 'shared/constants'
import { returnPolicyPath } from 'lib/urls'
import css from './FullSummary.styles.scss'
import { DiscountItem } from '../DiscountItem/DiscountItem'

// TODO: Refactor this to a design system component
const FreeShippingMessage = () => (
  <div className="free-shipping-message phm mtm mbl small">
    <img
      alt=""
      className="mhm"
      loading="lazy"
      role="presentation"
      src="https://images.babylist.com/image/upload/v1576013047/bl-heart_zyj97j.svg"
      width="30rem"
    />
    <div className="text-bold free-shipping-message-text">
      <div>{`Free shipping on orders $${FREE_SHIPPING_THRESHOLD}+`}</div>
      <div className="free-shipping-message-subtext">
        &{' '}
        <a className="free-shipping-message-link" href={returnPolicyPath}>
          free returns
        </a>{' '}
        from the Babylist Shop!
      </div>
    </div>
  </div>
)

export const FullSummary = ({
  checkoutTotals,
  isEligibleForRegistryDiscount,
}: Cart) => {
  const estimatedShippingValue =
    parseFloat(checkoutTotals.shipping) === 0 ? (
      <strong>FREE</strong>
    ) : (
      `${checkoutTotals.shipping}`
    )

  const hasShopSaleDiscount = parseFloat(checkoutTotals.saleTotalAmountOff) > 0

  const hasDiscounts =
    hasShopSaleDiscount ||
    parseFloat(checkoutTotals.registryDiscount) > 0 ||
    parseFloat(checkoutTotals.userCredit) > 0 ||
    isEligibleForRegistryDiscount

  return (
    <>
      <div className={css.FullSummary__item}>
        <span>Subtotal</span>
        <span data-testid="subtotal">
          $
          {hasShopSaleDiscount
            ? checkoutTotals.subtotalBeforeSales
            : checkoutTotals.subtotal}
        </span>
      </div>
      <div className={css.FullSummary__item}>
        <span>Estimated Tax</span>
        <span>${checkoutTotals.tax}</span>
      </div>
      <div className={css.FullSummary__item}>
        <span>Estimated Shipping*</span>
        <span>{estimatedShippingValue}</span>
      </div>
      {hasDiscounts && (
        <>
          <div className={css.FullSummary__item}>
            <span>Discounts</span>
          </div>
          <>
            <div>
              {parseFloat(checkoutTotals.userCredit) > 0 && (
                <DiscountItem
                  amount={checkoutTotals.userCredit}
                  label="Babylist Shop Credit"
                />
              )}
              {parseFloat(checkoutTotals.saleTotalAmountOff) > 0 && (
                <DiscountItem
                  amount={checkoutTotals.saleTotalAmountOff}
                  label="Babylist Shop Sale Discount"
                />
              )}
              {isEligibleForRegistryDiscount && (
                <RegistryDiscountDisplay
                  amount={parseFloat(checkoutTotals.registryDiscount)}
                  className="man pan pvs"
                />
              )}
            </div>
          </>
        </>
      )}
      <FreeShippingMessage />
      <div
        className={`${css.FullSummary__item} ${css['FullSummary__item--total']}`}
      >
        <span>Total</span>
        <span>${checkoutTotals.total}</span>
      </div>
    </>
  )
}
