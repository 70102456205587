import classNames from 'classnames'
import { TrackVisibility, track } from 'lib/analytics'

import EmailSignupForm from 'components/email-signup-form'
import {
  USER_SOURCE_EMAIL_PREGNANCY_WIDGET,
  USER_SOURCE_EMAIL_NEWSLETTER_WIDGET,
} from 'shared/constants'
import css from './email-callout.scss'

export enum EmailTypes {
  Pregnancy = 'pregnancy',
  Newsletter = 'newsletter',
}

interface EmailCalloutProps {
  emailType: EmailTypes
  emailUrlsByWeek: string[]
  nextPost: { slug: string; title: string }
}

export const EmailCallout = ({
  emailType,
  emailUrlsByWeek,
  nextPost,
}: EmailCalloutProps) => {
  let containerClass
  let userSource
  let descriptionText

  switch (emailType) {
    case EmailTypes.Pregnancy:
      userSource = USER_SOURCE_EMAIL_PREGNANCY_WIDGET
      containerClass = css.emailSignupPregnancy
      descriptionText = 'Keep your pregnancy on track. One email a week.'
      break
    case EmailTypes.Newsletter:
    default:
      containerClass = css.emailSignupGeneral
      userSource = USER_SOURCE_EMAIL_NEWSLETTER_WIDGET
      descriptionText =
        'The freshest in parenting this week, delivered to your inbox'
      break
  }

  const eventProperties = {
    event: track.emailCaptureViewed,
  }

  return (
    <div
      className={classNames(
        containerClass,
        css.emailSignupContainer,
        'clearfix flex-vertical-center center-block mbxl'
      )}
    >
      <div className={classNames(css.leftContainerBackground, 'hidden-xs')} />
      <div className={css.formContainer}>
        <TrackVisibility eventProperties={eventProperties}>
          <div className="h5 text-bold">{descriptionText}</div>
        </TrackVisibility>
        <EmailSignupForm
          emailType={emailType}
          emailUrlsByWeek={emailUrlsByWeek}
          nextPost={nextPost}
          userSource={userSource}
        />
      </div>
    </div>
  )
}

export default EmailCallout
