import React from 'react'
import classNames from 'classnames'
import {
  AmazonStore,
  BabylistStore,
  GenericStore,
  TargetStore,
  WalmartStore,
} from './svg'

import css from './StoreIcons.styles.scss'

import {
  StoreType,
  StoreComponents,
  StoreIconsProps,
  StoreIconProps,
} from './StoreIcons.types'

const storesData: StoreComponents = {
  babylist: BabylistStore,
  amazon: AmazonStore,
  walmart: WalmartStore,
  target: TargetStore,
  default: GenericStore,
}

export const StoreIcon: React.FC<StoreIconProps> = ({ store, className }) => {
  const StoreData = storesData[store.name] || storesData['default']

  return (
    <StoreData
      key={store.name}
      className={classNames(css.StoreIcons__icon, className)}
    />
  )
}

const StoreIcons: React.FC<StoreIconsProps> = ({
  className,
  stores,
  size = 'small',
  price,
  discount,
  inStock = true,
}) => {
  const StoreIconsClasses = classNames(
    css.StoreIcons,
    className,
    css[`StoreIcons__${size}`]
  )

  const additionalCount = Math.max(stores.length - 3, 0)

  return (
    <div className={StoreIconsClasses}>
      <div className={classNames(css.StoreIcons__wrapper)}>
        {stores.slice(0, 3).map((store: StoreType) => (
          <StoreIcon store={store} />
        ))}
        {additionalCount > 0 && (
          <div className={classNames(css.StoreIcons__additionalCount)}>
            +{additionalCount}
          </div>
        )}
      </div>
      <span className={classNames(css.StoreIcons__price)}>
        {price}
        {discount && (
          <span className={classNames(css.StoreIcons__discount)}>
            {discount}
          </span>
        )}
        {!inStock && (
          <span className={classNames(css.StoreIcons__outOfStock)}>
            In stock soon
          </span>
        )}
      </span>
    </div>
  )
}

export default StoreIcons
