import React from 'react'
import classNames from 'classnames'
import { Star } from 'baby-design/icons'

import css from './StarRating.styles.scss'
import { StarRatingProps } from './StarRating.types'

// The TurnTo stars round between X.25 and X.75. Between X.25 and X.75,
// we display a half star. Below X.25, we show X stars, and above X.75
// we show X+1 stars. In other words So 4.25 would show four and a half stars.
// 4.75 would show five full stars.
const roundingThreshold = { min: 0.25, max: 0.75 }

function formatToK(num: number): string {
  return num < 1000
    ? num.toString()
    : `${(num / 1000).toFixed(num % 1000 === 0 ? 0 : 1)}K`
}

const StarRating: React.FC<StarRatingProps> = ({
  className,
  rating,
  reviewCount,
  size = 'lg',
  showRating = true,
  showReviewCount = true,
}) => {
  const starRatingClasses = classNames(css.StarRating, className, {
    [css['StarRating--sm']]: size === 'sm',
    [css['StarRating--lg']]: size === 'lg',
  })

  const formattedRating = rating.toFixed(1)

  return (
    <div
      aria-label={`Rating: ${formattedRating} out of 5`}
      className={starRatingClasses}
      role="img"
    >
      <span className={css.StarRating__visuallyHidden}>
        Average rating: {rating} out of 5
      </span>
      <div className={css.StarRating__icons}>
        {[...Array(5)].map((_, idx) => {
          let fillState: 'empty' | 'half' | 'full' = 'empty'

          if (rating > idx + roundingThreshold.max) {
            fillState = 'full'
          } else if (
            rating >= idx + roundingThreshold.min &&
            rating < idx + roundingThreshold.max
          ) {
            fillState = 'half'
          }

          return (
            <Star
              className={css.StarRating__star}
              filled={fillState}
              key={idx}
            />
          )
        })}
      </div>
      {!!showRating && !!formattedRating && (
        <div className={css.StarRating__rating}>{formattedRating}</div>
      )}
      {!!showReviewCount && !!reviewCount && (
        <div className={css.StarRating__reviewCount}>
          ({formatToK(reviewCount)})
        </div>
      )}
    </div>
  )
}

export default StarRating
