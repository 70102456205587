import React from 'react'
import { AccountAttributesProps } from 'registry/components/CrowdfundReserveFormFlow/components/ContributionDetails/components/AccountAttributes/AccountAttributes.types'
import AccountAttributeField from 'registry/components/CrowdfundReserveFormFlow/components/ContributionDetails/components/AccountAttributeField/AccountAttributeField'
import classNames from 'classnames'
import css from './AccountAttributes.styles.scss'

const AccountAttributes: React.FC<AccountAttributesProps> = ({
  expanded = false,
  InputComponent,
  onSelectionChanged,
  preference,
  selected = false,
}) => {
  const accountAttributesClasses = classNames(css.AccountAttributes, {
    [css.AccountAttributes__Selected]: selected,
  })

  return (
    <div className={accountAttributesClasses}>
      <div className={css.AccountAttributes__Control}>
        {InputComponent && (
          <InputComponent
            checked={selected}
            size="md"
            type="primary"
            onChange={() => onSelectionChanged?.(preference.key)}
          />
        )}
        {preference.image && (
          <img
            alt={preference.name}
            className={css.AccountAttributes__Image}
            src={preference.image}
          />
        )}
        {!preference.image && (
          <p className={css.AccountAttributes__Text}>{preference.name}</p>
        )}
      </div>
      {expanded && (
        <>
          {preference.fields
            ?.filter((field) => !!field.value)
            ?.map((field) => (
              <AccountAttributeField
                canCopy
                key={field.name}
                label={field.label}
                prefix={field.prefix}
                value={field.value}
              />
            ))}
        </>
      )}
    </div>
  )
}

export default AccountAttributes
