import { useRef } from 'react'
import Image from 'components/image'
import { InfoButton } from 'components/buttons'
import { healthPath } from 'lib/urls'
import { OpenInNewWindowIcon } from 'components/icons'
import { RegistryBannerEvents } from 'baby-ui/compounds/RegistryBanner/RegistryBannerEvents'
import useViewedOnce from 'baby-ui/compounds/RegistryBanner/useViewedOnce'
import css from './checklist-category-breast-pump-banner.styles.scss'

export interface ChecklistCategoryBreastPumpBannerProps
  extends RegistryBannerEvents {
  showFreeGiftText?: boolean
}

export const ChecklistCategoryBreastPumpBanner = ({
  onView,
  onClick,
  showFreeGiftText = false,
}: ChecklistCategoryBreastPumpBannerProps) => {
  const bannerRef = useRef(null)
  useViewedOnce(bannerRef, onView)

  return (
    <a
      href={healthPath}
      ref={bannerRef}
      rel="noreferrer"
      target="_blank"
      onClick={onClick}
    >
      <div className={css.root}>
        <Image
          alt="sparkles"
          src="//images.babylist.com/image/upload/v1689614082/checklist/sparkles.svg"
        />
        <div>
          <p>
            <b>
              Order your free breast pump through insurance at Babylist Health!
            </b>
          </p>
          {showFreeGiftText && (
            <p className={css.smallText}>
              Order now and get a FREE set of breast milk storage bags.
            </p>
          )}
        </div>
        <InfoButton
          empty
          pill
          className={css.actionButton}
          href={healthPath}
          size="small"
          target="_blank"
        >
          <span>Check your eligibility</span> <OpenInNewWindowIcon />
        </InfoButton>
      </div>
    </a>
  )
}
