import { type GroupBase } from 'react-select'

import SelectInput from '../SelectInput/SelectInput'

import { DropdownInputProps } from './DropdownInput.types'
import { SelectInputRightIconProps } from '../SelectInput/SelectInput.types'
import Chevron from '../../../icons/Chevron.svg'
import css from './DropdownInput.styles.scss'

const RightIcon = ({ isFocused }: SelectInputRightIconProps) => {
  return <Chevron className={isFocused ? css.dropdown__icon_flipped : ''} />
}

const DropdownInput = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  isSearchable,
  ...props
}: DropdownInputProps<Option, IsMulti, Group>) => {
  return <SelectInput {...props} RightIcon={RightIcon} isSearchable={false} />
}

export default DropdownInput
