import { Radio, RadioGroup } from 'components/forms'
import {
  ConsolidatedShippingIcon,
  ConsolidatedShippingLeafIcon,
} from 'components/icons'
import { FieldProp } from 'redux-form'
import css from './consolidated-shipping-form-fields.scss'
import { useFeature } from 'flagged'

interface FormFieldProps {
  consolidationChoice: FieldProp<string>
}

export default ({ consolidationChoice }: FormFieldProps) => {
  const showHbbFreeShippingCopy = useFeature('showHbbFreeShippingCopy')
  const optInLabel = showHbbFreeShippingCopy ? (
    <div>
      <span className="text-bold">
        Yes, I want you to ship my Hello Baby Box for free.{' '}
        <ConsolidatedShippingLeafIcon className={css.leafIcon} />
      </span>
      <br />
      <span className="xsmall text-muted">
        I want my gifts shipped in fewer boxes* and understand that items may
        take up to 2 weeks to ship.
      </span>
    </div>
  ) : (
    <div>
      <span className="text-bold">
        Yes, I'd like to keep it simple! Ship my gifts in fewer boxes*{' '}
        <ConsolidatedShippingLeafIcon className={css.leafIcon} />
      </span>
      <br />
      <span className="xsmall text-muted">
        I understand that by selecting this, my gifts may take up to 2 weeks to
        ship to me.
      </span>
    </div>
  )

  const optOutLabel = showHbbFreeShippingCopy ? (
    <div>
      <span className="text-bold">
        No, I will pay $8.95 to ship my Hello Baby Box.
      </span>
      <br />
      <span className="xsmall text-muted">
        I prefer to receive my Babylist Shop gifts as soon as they are
        purchased.
      </span>
    </div>
  ) : (
    'No, send me Babylist Shop gifts as soon as they are purchased'
  )

  const introCopy = showHbbFreeShippingCopy
    ? `Choose Simple Shipping to get your Hello Baby Box delivered for free
      and get your gifts in fewer boxes. Better for your entryway and the
      environment, Simple Shipping bundles gifts purchased from the Babylist
      shop into fewer shipments.`
    : `Try Simple Shipping by Babylist! We'll bundle your gifts purchased
      from the Babylist Shop into fewer shipments.* Better for your entryway
      and the environment.`

  const { error, ...consolidationChoiceInputProps } = consolidationChoice

  return (
    <div>
      <div className={css.consolidationText}>
        <div className={css.consolidatedShippingIconContainer}>
          <ConsolidatedShippingIcon />
        </div>
        <div>
          {introCopy}
          {error && <div className="h7 text-danger mtm">Must select one:</div>}
        </div>
      </div>
      <div className="flex center">
        <RadioGroup className="mtxl mbl flex col">
          <Radio
            {...consolidationChoiceInputProps}
            label={optInLabel}
            checked={consolidationChoiceInputProps.value === 'rolling'}
            onChange={() => consolidationChoiceInputProps.onChange('rolling')}
          />
          <Radio
            {...consolidationChoiceInputProps}
            label={optOutLabel}
            checked={consolidationChoiceInputProps.value === 'none'}
            onChange={() => consolidationChoiceInputProps.onChange('none')}
          />
        </RadioGroup>
      </div>
    </div>
  )
}
