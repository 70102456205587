import classNames from 'classnames'
import PropTypes from 'prop-types'

import Image from 'components/image'
import {
  mobileAppDownloadPath,
  checklistPath,
  registryHomePath,
} from 'lib/urls'
import { InfoButton, CloseButton } from 'components/buttons'
import { Wave } from 'components/curves'
import { track } from 'lib/analytics'
import Link from 'components/link'

import css from './app-promo.scss'

const trackModalExit = (trackAttributes, onClose) => {
  track.appDownloadInterstitialSkipped(trackAttributes)
  onClose()
}
const trackModalCtaClick = (
  trackAttributes,
  path = mobileAppDownloadPath()
) => {
  track.appDownloadInterstitialClicked(trackAttributes)
  window.location.href = path
}

export const AppPromoWave = ({ trackAttributes, onClose }) => (
  <>
    <div className={classNames('text-center', css.promoWaveHeader)}>
      <div className={css.promoWaveHeaderEnd}>
        <CloseButton onClick={() => trackModalExit(trackAttributes, onClose)} />
      </div>
      <div className={`h1 ${css.promoWaveHeading}`}>
        Ready to add any item from any store?
      </div>
    </div>
    <div className={css.promoWaveComponentWrapper}>
      <Wave color="white" />
    </div>
    <div className={classNames('pbxxl text-center')}>
      <InfoButton
        pill
        className="mbl"
        onClick={() => trackModalCtaClick(trackAttributes)}
      >
        Get The Babylist App
      </InfoButton>
      <p>
        <Link
          onClick={() => trackModalExit(trackAttributes, onClose)}
          className={classNames('small', css.promoWaveLink)}
        >
          Continue browsing the mobile site
        </Link>
      </p>
    </div>
  </>
)

AppPromoWave.propTypes = {
  onClose: PropTypes.func,
  trackAttributes: PropTypes.object,
}

AppPromoWave.defaultProps = {
  onClose: () => {},
  trackAttributes: {},
}

export const AppPromoStartBuilding = ({ trackAttributes }) => (
  <div className={css.AppPromoStartBuildingModal__contentContainer}>
    <div className={css.AppPromoStartBuildingModal__header}>
      <h1 className={css.AppPromoStartBuildingModal__heading}>
        Register for items
        <br />
        from <span>any store,</span>
        <br />
        all in <span>one place</span>
      </h1>
    </div>
    <Image
      className={css.AppPromoStartBuildingModal__image}
      src="https://images.babylist.com/image/upload/f_auto,q_auto/v1691177559/Onboarding/mobile_interstitial_ex5_v2.png"
    />
    <div className={css.AppPromoStartBuildingModal__bottomSection}>
      <p className={css.AppPromoStartBuildingModal__disclaimerText}>
        You'll need our 5-star app to register for anything right from your{' '}
        phone
      </p>
      <InfoButton
        pill
        className={css.AppPromoStartBuildingModal__appDownloadButton}
        onClick={() => trackModalCtaClick(trackAttributes)}
      >
        Get The Babylist App
      </InfoButton>
    </div>
  </div>
)

export const AppPromoStartBuildingActEx2Variant = ({ trackAttributes }) => {
  const { experimentGroup } = trackAttributes
  const ctaPath =
    experimentGroup === 'variation1' ? checklistPath : registryHomePath
  return (
    <>
      <div
        className={classNames(
          'text-center',
          css.promoWaveHeader,
          css.promoWaveHeaderVariant
        )}
      >
        <h1 className={css.promoWaveHeadingVariant}>
          Crush your checklist
          <Image
            className={css.promoWaveHeadingIcon}
            src="https://images.babylist.com/image/upload/f_auto,q_auto/v1694202888/Onboarding/flex_hq.png"
          />
        </h1>
        <p
          className={classNames(
            'small',
            css.promeWaveDisclaimerText,
            css.promoWaveDisclaimerTextVariant
          )}
        >
          Add items from any store and complete your checklist faster than you
          can say <strong>'abracadabra'</strong>
        </p>
      </div>
      <div className={css.promoWaveImageVariantContainer}>
        <Image
          className={css.promoWaveImageVariant}
          src="https://images.babylist.com/image/upload/f_auto,q_auto/v1694097466/Onboarding/checklist-image.png"
        />
        <Image
          className={css.promoWaveImageVariantArrow}
          src="https://images.babylist.com/image/upload/f_auto,q_auto/v1694204143/Onboarding/arrow.png"
        />
        <div className={css.promoWaveImageVariantLabel}>
          <div>We will track your progress</div>
        </div>
      </div>
      <div className={classNames('pbxxl text-center')}>
        <InfoButton
          pill
          className={css.promoWaveAppDownloadButton}
          onClick={() =>
            trackModalCtaClick(trackAttributes, mobileAppDownloadPath(ctaPath))
          }
        >
          Get checkin' in the app
        </InfoButton>
      </div>
    </>
  )
}

export const AppPromoNextStep = ({ onClose }) => (
  <>
    <div className={classNames('text-center', css.promoWaveHeader)}>
      <div className={css.promoWaveHeaderEnd}>
        <CloseButton onClick={onClose} />
      </div>
      <h1 className={css.promoWaveHeading}>Next Step: Get The App</h1>
      <p className={classNames('tiny', css.promoWaveSubHeading)}>
        You'll need our 5-star rated app to add items from any online store
        (from your mobile device).
      </p>
    </div>
    <div className={css.promoWaveComponentWrapperSmall}>
      <Wave color="white" />
    </div>
    <div className={classNames('pbxxl text-center')}>
      <InfoButton href={mobileAppDownloadPath()} className="mbl" pill>
        Get The Babylist App
      </InfoButton>
      <p className={classNames('tiny', css.promeWaveDisclaimerText)}>
        Nah, I want to{' '}
        <Link
          onClick={onClose}
          className={classNames('tiny', css.promoWaveLink)}
        >
          browse the mobile site
        </Link>{' '}
        and add items from Babylist Shop only.
      </p>
    </div>
  </>
)

export const AppPromoCollaborator = ({ registrantNames }) => {
  return (
    <>
      <div className={classNames('text-center', css.promoWaveHeader)}>
        <h1 className={css.promoWaveHeading}>
          Get the app to help
          <br />
          {registrantNames}
          <br />
          build their registry.
        </h1>
        <p className={classNames('tiny', css.promoWaveSubHeading)}>
          On your phone? You'll need the app.
        </p>
      </div>
      <div className={css.promoWaveComponentWrapperSmall}>
        <Wave color="white" />
      </div>
      <div className={classNames('pbxxl text-center')}>
        <InfoButton href={mobileAppDownloadPath()} className="mbl" pill>
          Get The Babylist App
        </InfoButton>
      </div>
    </>
  )
}

AppPromoNextStep.propTypes = {
  onClose: PropTypes.func,
}

AppPromoNextStep.defaultProps = {
  onClose: () => {},
}
