import { useEffect } from 'react'
import classnames from 'classnames'
import { SearchTerm } from 'baby-ui/lib/SearchTerm'
import { SearchBannerButton } from 'baby-ui/compounds/RegistryBanner/SearchBannerButton'
import { BannerProportion } from 'baby-ui/compounds/RegistryBanner/BannerProportion'
import { healthPath } from 'lib/urls'
import { RegistryBannerEvents } from 'baby-ui/compounds/RegistryBanner/RegistryBannerEvents'
import css from './search-banner.styles.scss'

export interface SearchResultBreastPumpBannerProps
  extends RegistryBannerEvents {
  proportion?: BannerProportion
  query: string
  showFreeGiftText?: boolean
}

export const SearchBreastPumpBanner = ({
  onClick,
  onView,
  proportion = BannerProportion.Spacious,
  query,
  showFreeGiftText = false,
}: SearchResultBreastPumpBannerProps) => {
  const searchTerm = new SearchTerm(query)
  const componentShouldRender = searchTerm.hasBabylistHealthKeywords

  useEffect(() => {
    if (componentShouldRender) {
      onView?.()
    }
  }, [])

  if (!componentShouldRender) return null

  return (
    <a href={healthPath} rel="noreferrer" target="_blank" onClick={onClick}>
      <div
        className={classnames(css.banner, {
          [css.bannerSpacious]: proportion === BannerProportion.Spacious,
          [css.bannerCompact]: proportion === BannerProportion.Compact,
          [css.withFreeGiftTextTweaks]: showFreeGiftText,
        })}
      >
        <div
          className={classnames(css.bannerThumbnail, {
            [css.bannerThumbnailSpacious]:
              proportion === BannerProportion.Spacious,
            [css.bannerThumbnailCompact]:
              proportion === BannerProportion.Compact,
          })}
        />
        <div
          className={classnames(css.bannerContent, {
            [css.bannerContentSpacious]:
              proportion === BannerProportion.Spacious,
            [css.bannerContentCompact]: proportion === BannerProportion.Compact,
          })}
        >
          <div className={css.contentTextWrapper}>
            <h2 className={css.bannerHeading}>
              Order your{' '}
              <span className={css.bannerHeadingEmphasized}>
                free breast pump
              </span>{' '}
              through insurance at{' '}
              <span className={css.noTextWrap}>Babylist Health!</span>
            </h2>
            {showFreeGiftText && (
              <p className={css.freeGiftText}>
                Place your order and get a FREE set of 120 breast milk storage
                bags for a limited time only.
              </p>
            )}
          </div>
          <SearchBannerButton />
        </div>
      </div>
    </a>
  )
}
