import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import css from './TextAreaInput.styles.scss'

import { TextAreaInputProps } from './TextAreaInput.types'

const textAreaSizeClassname = (size: TextAreaInputProps['size']) => {
  switch (size) {
    case 'sm':
      return css.input__small
    case 'md':
      return css.input__medium
    case 'lg':
      return css.input__large
  }
}

const textAreaTypeClassname = (type: TextAreaInputProps['type']) => {
  switch (type) {
    case 'primary':
      return css.input__primary
  }
}

const TextAreaInput: React.FC<TextAreaInputProps> = ({
  label,
  size,
  status,
  className,
  maxLength,
  type = 'primary',
  onChange = () => {},
  ...props
}) => {
  const TextAreaInputClasses = classNames(
    css.TextAreaInput,
    textAreaSizeClassname(size),
    textAreaTypeClassname(type),
    { [css.input__status_error]: status?.type === 'error' },
    className
  )

  // TODO: Once we are on React 18 we can use the useId() hook to generate a unique ID for the tooltip
  // Until then this little trick will convert a random number to a base 36 string
  const [uuid1] = useState<string>(Math.random().toString(36).substring(2))
  const labelMessageId = uuid1

  const [uuid2] = useState<string>(Math.random().toString(36).substring(2))
  const statusMessageId = uuid2

  const [textAreaValue, setTextAreaValue] = useState(props.value)

  const decoratedOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(event.target.value)
    onChange(event)
  }

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px'
      const scrollHeight = textareaRef.current.scrollHeight
      const textAreaPaddingRem = 0.75
      textareaRef.current.style.height =
        scrollHeight - 2 * (textAreaPaddingRem * 16) + 'px'
    }
  }, [textAreaValue])

  const hasStatusMessage = status && status.message
  const shouldShowHelpers = hasStatusMessage || maxLength !== undefined

  return (
    <div className={TextAreaInputClasses}>
      {label && (
        <label htmlFor={labelMessageId} className={css.input__label}>
          {label}
        </label>
      )}
      <textarea
        id={labelMessageId}
        ref={textareaRef}
        className={css.input__text_area}
        aria-invalid={status?.type === 'error'}
        aria-errormessage={
          status?.type === 'error' ? statusMessageId : undefined
        }
        onChange={decoratedOnChange}
        maxLength={maxLength}
        {...props}
      />
      {shouldShowHelpers && (
        <div className={css.input__helpers}>
          {hasStatusMessage && (
            <div
              id={statusMessageId}
              className={css.input__status_message}
              role={status.type === 'info' ? 'status' : undefined}
            >
              {status.message}
            </div>
          )}
          {maxLength !== undefined && (
            <div className={css.input__max_len_counter}>
              {typeof textAreaValue === 'string' ? textAreaValue.length : 0} /{' '}
              {maxLength}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default TextAreaInput
