import { shippingConsolidationHelpPath } from 'lib/urls'

export default () => (
  <div className="tiny">
    * Simple Shipping by Babylist only applies to items purchased from the
    Babylist Shop, and NOT from other retailers like Target, Pottery Barn etc.
    Bundled shipments may contain gifts from multiple friends & family members.{' '}
    <a href={shippingConsolidationHelpPath} target="_blank">
      Exclusions apply.
    </a>
  </div>
)
