import React from 'react'

interface GenericStoreProps {
  className?: string
  rounded?: boolean
}

const GenericStore: React.FC<GenericStoreProps> = ({
  className,
  rounded = true,
}: GenericStoreProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="#252324"
      />
    ) : (
      <rect width="24" height="24" fill="#252324" />
    )}
    <path
      d="M6.66667 9.576C6.66667 8.36629 6.66667 7.76143 6.89919 7.29938C7.10372 6.89295 7.43007 6.56251 7.83149 6.35543C8.28783 6.12 8.88522 6.12 10.08 6.12H13.92C15.1148 6.12 15.7122 6.12 16.1685 6.35543C16.5699 6.56251 16.8963 6.89295 17.1008 7.29938C17.3333 7.76143 17.3333 8.36629 17.3333 9.576V14.544C17.3333 15.7537 17.3333 16.3586 17.1008 16.8206C16.8963 17.227 16.5699 17.5575 16.1685 17.7646C15.7122 18 15.1148 18 13.92 18H10.08C8.88522 18 8.28783 18 7.83149 17.7646C7.43007 17.5575 7.10372 17.227 6.89919 16.8206C6.66667 16.3586 6.66667 15.7537 6.66667 14.544V9.576Z"
      fill="#F5F5F5"
    />
    <path
      d="M17.5353 6.93806C17.4166 6.45728 16.99 6.12 16.5005 6.12H7.4995C7.01004 6.12 6.58339 6.45728 6.46468 6.93806L5.6 10.44C5.6 11.6329 6.55513 12.6 7.73333 12.6C8.91154 12.6 9.86667 11.6329 9.86667 10.44C9.86667 11.6329 10.8218 12.6 12 12.6C13.1782 12.6 14.1333 11.6329 14.1333 10.44C14.1333 11.6329 15.0885 12.6 16.2667 12.6C17.4449 12.6 18.4 11.6329 18.4 10.44L17.5353 6.93806Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 10.2406L4.91245 6.54515C5.20923 5.3432 6.27585 4.5 7.4995 4.5H16.5005C17.7242 4.5 18.7908 5.3432 19.0876 6.54515L20 10.2406V10.44C20 12.5276 18.3285 14.22 16.2667 14.22C15.4735 14.22 14.738 13.9695 14.1333 13.5425C13.5286 13.9695 12.7932 14.22 12 14.22C11.2068 14.22 10.4714 13.9695 9.86667 13.5425C9.26196 13.9695 8.52653 14.22 7.73333 14.22C5.67147 14.22 4 12.5276 4 10.44V10.2406ZM8.76632 12.3303C9.4225 11.962 9.86667 11.2535 9.86667 10.44C9.86667 11.2535 10.3108 11.962 10.967 12.3303C11.2731 12.5022 11.6253 12.6 12 12.6C12.3748 12.6 12.7269 12.5022 13.033 12.3303C13.6892 11.962 14.1333 11.2535 14.1333 10.44C14.1333 11.2535 14.5775 11.962 15.2337 12.3303C15.5397 12.5022 15.8919 12.6 16.2667 12.6C17.4449 12.6 18.4 11.6329 18.4 10.44L17.5353 6.93806C17.4166 6.45728 16.99 6.12 16.5005 6.12H7.4995C7.01004 6.12 6.58339 6.45728 6.46468 6.93806L5.6 10.44C5.6 11.6329 6.55513 12.6 7.73333 12.6C8.10808 12.6 8.46026 12.5022 8.76632 12.3303Z"
      fill="#252324"
    />
  </svg>
)

export default GenericStore
