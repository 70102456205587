export class SearchTerm {
  public readonly value: string

  public static readonly babylistHealthKeywords = [
    "Dr. Brown's",
    'Baby Buddha',
    'Baby Budha',
    'BabyBuddha',
    'Bodily',
    'Breast',
    'Breastfeeding',
    'Breastmilk collector',
    'Breasts',
    'Dr Brown',
    'Dr Browns',
    'Elvie',
    'Flange',
    'Kindred Bravely',
    'Lansinoh',
    'Legendairy',
    'Manual breast pump',
    'Medela',
    'Milk bags',
    'Milk bag',
    'Milk collection',
    'Milk storage',
    'Momcozy',
    'Nipple Shield',
    'Nipple cream',
    'Nursing',
    'Pump',
    'Pumping',
    'Pumps',
    'Spectra',
    'Willow',
  ]

  constructor(term: string) {
    this.value = term.trim().toLowerCase()
  }

  get hasBabylistHealthKeywords() {
    if (!this.value) return false
    const pattern = this.babylistHealthTermsPattern()
    const regex = new RegExp(pattern, 'i')
    return regex.test(this.value)
  }

  private babylistHealthTermsPattern() {
    const words = SearchTerm.babylistHealthKeywords
      .map((term) => `${term}`)
      .join('|')
    return `\\b(${words})\\b`
  }
}
