/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import classNames from 'classnames'
import * as blUrls from 'lib/urls'
import Image from 'components/image'
import Link from 'components/link'
import { Accordion } from 'components/accordion'
import { withContextualizedTracking } from 'lib/analytics'
import { setHost } from 'lib/urls'
import css from './Footer.scss'
import { getNav } from './constants'
import SocialLinks from './SocialLinks'
import GooglePlayBadge from './AppStoreBadges/GooglePlayBadge.svg'
import AppleStoreBadge from './AppStoreBadges/AppleStoreBadge.svg'

interface AccordionTriggerProps {
  onClick: () => void
  isOpen: boolean
}

interface NavItemProps {
  name: string
  href: string
  newWindow?: boolean
  onClick?: () => void
  mobileOnly?: boolean
  render?: boolean
}

interface FooterProps {
  expandAll: boolean
  loggedIn: boolean
  _canonicalHost: string
}

const Footer = ({ expandAll, loggedIn, _canonicalHost }: FooterProps) => {
  setHost(_canonicalHost) // Sets the host for SSR absolute URLs
  const [linkLimit, setLinkLimit] = useState({} as Record<string, number>)

  const expandNavGroup = (heading: string, length: number) => () => {
    setLinkLimit((prevLinkLimits: Record<string, number>) => ({
      ...prevLinkLimits,
      [heading]: length === prevLinkLimits[heading] ? 10 : length,
    }))
  }

  return (
    <div>
      <div className={css.footerRow}>
        <Link aria-label="home" url={blUrls.absoluteUrl(blUrls.indexPath)}>
          <Image
            alt="babylist-logo"
            className="mvl visible-xs col-centered"
            height="38"
            src="//res.cloudinary.com/babylist/image/upload/f_auto,q_auto/v1549314083/Babylist_logo_horizontal_white_qltnll.svg"
            width="170"
          />
        </Link>
      </div>
      <div className={css.footerRow}>
        {getNav(loggedIn).map((navGroup) => (
          <div
            className={css.footerRowColumn}
            key={`${navGroup.heading}_container`}
          >
            <Accordion
              defaultOpen={expandAll}
              trigger={({ onClick, isOpen }: AccordionTriggerProps) => (
                <button
                  className={css.collapsibleHeader}
                  type="button"
                  onClick={onClick}
                >
                  <div
                    className={`h7 ${css.footerNavGroupHeader} mvm text-bold`}
                  >
                    <a
                      className={`${css.navGroupLink} ${css.textLink}`}
                      title={navGroup.title}
                      href={blUrls.absoluteUrl(navGroup.href)}
                    >
                      {navGroup.heading}
                    </a>
                  </div>
                  <img
                    alt={`Collapsible question ${
                      isOpen ? 'closed' : 'open'
                    } icon`}
                    className={classNames(css.chevron, {
                      [css.rotate]: isOpen,
                    })}
                    src="https://images.babylist.com/image/upload/v1686326413/Dynamic%20Landing%20Pages/FAQ_chevron_down.svg"
                  />
                </button>
              )}
            >
              <div>
                <ul className="list-unstyled mbl">
                  {navGroup.links
                    .slice(
                      0,
                      expandAll
                        ? navGroup.links.length
                        : linkLimit[navGroup.heading] || 10
                    )
                    .map((navItem: NavItemProps) =>
                      navItem.render !== false ? (
                        <li className="mvm" key={navItem.name}>
                          <Link
                            className={classNames(css.textLink, {
                              [css.mobileOnlyLinks]: navItem.mobileOnly,
                            })}
                            newWindow={navItem.newWindow}
                            onClick={navItem.onClick}
                            url={blUrls.absoluteUrl(navItem.href)}
                          >
                            {navItem.name}
                          </Link>
                        </li>
                      ) : null
                    )}
                  {!expandAll && navGroup.links.length > 10 && (
                    <li className="mvm" key={`${navGroup.heading}_expand`}>
                      <Link
                        className={`${css.textLink} ${css.expandLink}`}
                        onClick={expandNavGroup(
                          navGroup.heading,
                          navGroup.links.length
                        )}
                      >
                        {`See ${
                          linkLimit[navGroup.heading] === navGroup.links.length
                            ? 'Less'
                            : 'More'
                        }`}
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </Accordion>
          </div>
        ))}
      </div>
      <div className={`mtl ${css.footerRow}`}>
        <div className={`${css.footerRowColumn} ${css.socialRowColumn}`}>
          <SocialLinks className="mtl mbl" />
        </div>
        <div className={`${css.footerRowColumn} ${css.socialRowColumn}`}>
          <div className="mtl mbxl">
            <div className="h7 text-uppercase mvl text-bold">
              Download our app
            </div>
            <ul className={classNames(css.app, 'list-inline mbl mtl')}>
              <li>
                <a
                  href={blUrls.absoluteUrl(blUrls.mobileAppDownloadPath())}
                  rel="noreferrer"
                  target="_blank"
                >
                  <AppleStoreBadge />
                  <span className="sr-only">
                    Download on the Apple App Store
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={blUrls.absoluteUrl(blUrls.mobileAppDownloadPath())}
                  rel="noreferrer"
                  target="_blank"
                >
                  <GooglePlayBadge />
                  <span className="sr-only">
                    Download on the Google Play Store
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={css.footerRow}>
        <div className={`${css.footerRowColumn} ${css.socialRowColumn}`}>
          <p className={`${css.copyRow} ptl`}>
            &copy; 2011 - {new Date().getFullYear()} Babylist, Inc
          </p>
        </div>
      </div>
    </div>
  )
}

export default withContextualizedTracking()(Footer)
