export const LOCK_BODY_SCROLL_CLASS = 'scroll-lock'
let lockRequests: Set<string> = new Set()

export const lockBodyScroll = (lockSourceName: string) => {
  if (typeof window !== 'undefined') {
    lockRequests.add(lockSourceName)
    applyLockState()
  }
}

export const unlockBodyScroll = (lockSourceName: string) => {
  if (typeof window !== 'undefined') {
    lockRequests.delete(lockSourceName)
    applyLockState()
  }
}

const applyLockState = () => {
  if (typeof window !== 'undefined') {
    const bodyClassList = document.body.classList

    if (lockRequests.size > 0) {
      bodyClassList.add(LOCK_BODY_SCROLL_CLASS)
    } else {
      bodyClassList.remove(LOCK_BODY_SCROLL_CLASS)
    }
  }
}
