import { Col, Row } from 'react-bootstrap'
import { chunk, map } from 'lodash'
import { filterPostsByTopicSlug } from 'hello_baby/lib'
import PostList from '../post-list'
import css from './GearView.scss'

const NUM_COLUMNS = 2

interface GearViewProps {
  posts: any[]
  subTopics: any[]
}

const GearView = ({ posts, subTopics }: GearViewProps) => (
  <>
    {map(chunk(subTopics, NUM_COLUMNS), (subTopicsRow) => (
      <Row>
        {map(subTopicsRow, (subTopic) => (
          <Col
            key={subTopic.slug}
            sm={12 / NUM_COLUMNS}
            className={css.postColumn}
          >
            <PostList
              topic={subTopic}
              posts={filterPostsByTopicSlug(posts, subTopic.slug)}
            />
          </Col>
        ))}
      </Row>
    ))}
  </>
)

export default GearView
