import Cookies from 'js-cookie'

export const FBC_BACKUP_COOKIE_NAME = 'fbclid_bl_backup'

// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
export const getFBPCookie = function (): string | null {
  try {
    return Cookies.get('_fbp') || null
  } catch (e) {
    return null
  }
}

export const getFBCCookie = function (): string | null {
  try {
    const cookie = Cookies.get('_fbc')
    if (!cookie) {
      return Cookies.get(FBC_BACKUP_COOKIE_NAME) || null
    }
    return cookie
  } catch (e) {
    return null
  }
}
